import { lightTheme } from 'amazon-chime-sdk-component-library-react';

const theme: any = {
  color: {
    primary: '#0000FF',
    secondary: '#A0A3BD',
    lightBlue: '#23BFF2',
    lightGreen: '#40E5BF',
    lightYellow: '#FFD058',
    purple: '#8F39DF',
    orange: '#FF8519',
    grey: '#BFBFBF',
    lightGrey: '#E5E5FF',
    green: '#27AE60',
    red: '#EC2D2D',
    blue: '#39CAF9',
    yellow: '#FAAD14',
    dark: '#262626',
    white: '#FFFFFF',
    none: 'RGBA(0,0,0,0.0)',
    workinnBlue: '#0CBEF8',
    workinnGreen: '#40E5BF',
    workinnYellow: '#FFD058',
    workinnRed: '#EB5757',
    workinnViolet: '#8F39DF',
    disabled: '#F5F5F5',
  },
  fontSize: {
    small: '12px',
    normal: '14px',
    medium: '16px',
    big: '18px',
    large: '25px',
    huge: '34px',
    gigantic: '50px',
  },
  lineHeight: {
    small: '18px',
    normal: '20px',
    medium: '22px',
    big: '24px',
    large: '33px',
    huge: '41px',
    gigantic: '70px',
  },
  fontWeight: {
    regular: 500,
    bold: 700,
  },
  screenMaxWidth: {
    xs: 'max-width: 576px',
    sm: 'max-width: 767px',
    md: 'max-width: 991px',
    lg: 'max-width: 1199px',
  },
  screenWidth: {
    xs: 576,
    sm: 767,
    md: 991,
    lg: 1199,
  },
  hexToRGBA: (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r},${g},${b}, ${alpha})`;
  },
  ...lightTheme,
};

export default theme;
