import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileApi from '../api/Profile';
import { TEarningsStats } from '../api/account/types';
import customNotification from '../components/notification';
import { getErrorFromResponse } from '../helpers/functions';
import { TWorkersResponse } from '../api/Workers';

export type Translation = {
  language_tag: string;
  translation: string;
};

export type TranslationSkills = {
  id: number;
  translations: Translation[];
};

export type SpecialitieType = {
  id: number;
  speciality: string;
  translations: Translation[];
};

export type TData = {
  description: string;
  avatar: string;
  logo?: string;
  first_name: string;
  last_name: string;
  sex: 'MALE' | 'FEMALE' | string;
  city: string;
  rating: {
    organization: number;
    quality: number;
    atmosphere: number;
    communication: number;
  } | null;
  rating_count: number;
  profile_completed: boolean;
  careers: TranslationSkills[];
  applications?: Array<{
    speciality: SpecialitieType;
    event_dates: Array<Array<string>>;
    id: number;
    location__city: string;
  }>;
  employment_declaration?: Array<{
    key: string;
    value: true;
  }>;
  abilities: TranslationSkills[];
  hours_worked: number;
  orders_count: number;
  dimensions?: {
    weight: number;
    height: number;
    hips: number;
    waist: number;
    chest: number;
  };
  experience: Array<{
    id: number;
    rating: {
      organization: number;
      quality: number;
      atmosphere: number;
      communication: number;
    } | null;
    rating_count: number;
    is_workinn: boolean;
    company: string;
    position: string;
    description: string;
    created_at: string;
  }>;
};

export type TWorkerContextState = {
  loading: boolean;
  data: TData;
  earnings: TEarningsStats;
  getEarnings: () => void;
  getWorkerData: (id: number | string) => void;
  setData: (data: TData) => void;
  clearContext: () => void;
};

export const workerContextDefaultState: TWorkerContextState = {
  loading: false,
  data: {
    hours_worked: 0,
    orders_count: 0,
    description: '',
    avatar: '',
    first_name: '',
    last_name: '',
    sex: '',
    city: '',
    rating: null,
    rating_count: 0,
    profile_completed: false,
    careers: [],
    experience: [],
    employment_declaration: undefined,
    abilities: [],
  },
  earnings: {
    salary: 0,
    worked_hours: 0,
    applications_count: 0,
    works: [],
  },
  getEarnings: () => {},
  getWorkerData: () => {},
  setData: () => {},
  clearContext: () => {},
};

export const WorkerContext = createContext<TWorkerContextState>(
  workerContextDefaultState
);

const api = new ProfileApi();

const WorkerContextProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<TWorkerContextState['data']>(
    workerContextDefaultState.data
  );

  const [loading, setLoading] = useState<TWorkerContextState['loading']>(
    workerContextDefaultState.loading
  );

  const [earnings, setEarnings] = useState<TWorkerContextState['earnings']>(
    workerContextDefaultState.earnings
  );

  const getEarnings = async () => {
    const earningsData = await api.getAllEarningsStats();
    setEarnings(earningsData);
  };

  const { t } = useTranslation();

  const getWorkerData = async (id: number | string) => {
    setLoading(true);
    try {
      const workerData: any = await api.getWorker(id);
      setData(workerData);
    } catch (err: any) {
      const details: Record<string, any> = err?.response?.data;
      const error = getErrorFromResponse(details?.errors[0]);
      customNotification({
        type: 'error',
        description: t(
          `userDetailsForm.errors.${error.field !== null ? error.field + '_' : ''}${
            error.code
          }`
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  const clearContext = () => {
    setData(workerContextDefaultState.data);
  };

  return (
    <WorkerContext.Provider
      value={{
        data,
        earnings,
        getEarnings,
        getWorkerData,
        loading,
        setData,
        clearContext,
      }}>
      {children}
    </WorkerContext.Provider>
  );
};

export default WorkerContextProvider;
