import moment from 'moment';
import { dateTimeFormat } from '../helpers/constants';
import { createUrl } from '../helpers/functions';
import HttpClient from '../utils/http-client';
import { environmentConfig } from '../Config';
import { SpecialitieType, TranslationSkills } from '../context/WorkerContext';

const PATHS = {
  myOffers: '/v1/offers/my/',
};

export type TWorker = {
  worker_profile: {
    avatar: string;
    id: number;
    first_name: string;
    rating: null;
    rating_count: number;
    last_name: string;
  };
  status: TWorkerStatus;
};

export enum TWorkerStatus {
  candidate = 'CANDIDATE',
  confirmed = 'CONFIRMED',
  forSettlement = 'FOR_SETTLEMENT',
  settled = 'SETTLED',
  unsettled = 'UNSETTLED',
  notCome = 'WORKER_NOT_COME',
  settlementToImprove = 'SETTLEMENT_TO_IMPROVED',
  settlementSent = 'SETTLEMENT_SENT',
  executed = 'EXECUTED',
  rejected = 'REJECTED',
}

export type TOffer = {
  id: number;
  title: string;
  event_dates: Array<Array<string>>;
  location: {
    city: string;
    address: string;
  };
  speciality: SpecialitieType;
  abilities: TranslationSkills[];
  contents: string;
  requirements: string;
  hourly_rate: string;
  number_of_vacancies: number;
  number_of_workers_needed?: number;
  compatible: boolean;
  applied: boolean;
  can_application: {
    status: boolean;
    reasons?: Array<string>;
  };
  employer_profile: number;
  created_at: string;
  logo?: string;
  company_name?: string;
  status?: string;
  workers?: Array<TWorker>;
  can_send_opinion?: boolean;
  can_open_settlement?: boolean;
};

export type TData = {
  hourly_rate: number;
  number_of_vacancies: number;
  dateTimeRange: Array<{ start: string; end: string }>;
  location: string | { city: string; address: string; zip_code: string };
  speciality: string;
  abilities: TranslationSkills[];
  contents: string;
  sex?: string;
  requirements?: string;
};

export type TOffersResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<TOffer>;
};

export type TOffersQueryParams = {
  search?: string;
  ordering?: string;
  page?: number;
  page_size?: number;
  rate_min?: number;
  rate_max?: number;
  speciality?: Array<number>;

  distance?: string;
  city_lng?: number;
  city_lat?: number;
};

export type TMyOffersQueryParams = {
  page?: number;
  page_size?: number;
  status?: string | Array<string>;
};

class OffersApi extends HttpClient {
  constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public getMyOffers = async (queryParams?: any) =>
    this.instance.get<any, TOffersResponse>(createUrl(PATHS.myOffers, queryParams));
}

export default OffersApi;
