import { createContext, useState } from 'react';
import ProfileApi from '../api/Profile';
import { TOffersStats, TWorkersStats } from '../api/account/types';
import { SpecialitieType } from './WorkerContext';

export type TData = {
  city: string;
  logo: string;
  avatar?: string;
  company_name: string;
  website: string;
  rating: {
    organization: number;
    quality: number;
    atmosphere: number;
    communication: number;
  } | null;
  rating_count: number;
  description: string;
  institution: string;
  job_offer_count: string;
  worker_count: number;
  verified?: boolean;
  job_offers: Array<{
    id: number;
    event_dates: Array<Array<string>>;
    location: {
      city: string;
      address: string;
    };
    hourly_rate: string;
    is_active: boolean;
    speciality: SpecialitieType;
    title: string;
    opinions: Array<{
      rating_count: number;
      rating: {
        atmosphere: string;
        average: string;
        communication: string;
        id: number;
        organization: string;
        quality: string;
      };
    }> | null;
  }>;
};

export type TEmployerContextState = {
  loading: boolean;
  data: TData;
  offers: TOffersStats;
  workers: TWorkersStats;
  setData: (data: TData) => void;
  getEmployerData: (id: number) => void;
  getWorkers: () => void;
  getOffers: () => void;
  clearContext: () => void;
};

export const employerContextDefaultState: TEmployerContextState = {
  loading: false,
  offers: [],
  workers: {
    salary: 0,
    workers: [],
  },
  data: {
    city: '',
    logo: '',
    company_name: '',
    institution: '',
    website: '',
    rating: null,
    rating_count: 0,
    description: '',
    job_offer_count: '',
    verified: false,
    worker_count: 0,
    job_offers: [],
  },
  setData: () => {},
  getEmployerData: id => {},
  getWorkers: () => {},
  getOffers: () => {},
  clearContext: () => {},
};

export const EmployerContext = createContext<TEmployerContextState>(
  employerContextDefaultState
);

const api = new ProfileApi();

const EmployerContextProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<TEmployerContextState['data']>(
    employerContextDefaultState.data
  );
  const [offers, setOffers] = useState<TEmployerContextState['offers']>(
    employerContextDefaultState.offers
  );
  const [workers, setWorkers] = useState<TEmployerContextState['workers']>(
    employerContextDefaultState.workers
  );
  const [loading, setLoading] = useState<TEmployerContextState['loading']>(
    employerContextDefaultState.loading
  );

  const getEmployerData = async (id: number) => {
    setLoading(true);
    const employerData = await api.getEmployer(id);
    setData(employerData);
    setLoading(false);
  };

  const clearContext = () => {
    setData(employerContextDefaultState.data);
  };

  const getOffers = async () => {
    const offersData = await api.getAllOffersStats();

    setOffers(offersData);
  };
  const getWorkers = async () => {
    const workersData = await api.getAllWorkersStats();
    setWorkers(workersData);
  };

  return (
    <EmployerContext.Provider
      value={{
        loading,
        data,
        offers,
        workers,
        getWorkers,
        getOffers,
        getEmployerData,
        setData,
        clearContext,
      }}>
      {children}
    </EmployerContext.Provider>
  );
};

export default EmployerContextProvider;
