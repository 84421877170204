import { useContext, useEffect, useState } from 'react';
import { Layout, Menu, Dropdown, Checkbox, Button, Tooltip } from 'antd';
import { DeleteOutlined, MailOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SvgIcon, { IconType } from './SvgIcon';
import THEME from '../utils/theme';
import Text from './Text';
import paths from '../paths.json';
import Tabs from './profile/Tabs';
import { AuthContext } from '../context/AuthContext';
import { ProfileContext } from '../context/ProfileContext';
import { FavoriteContext } from '../context/FavoritesContext';
import useWindowDimensions from '../utils/WindowDimension';
import UserMenu from './userMenu/UserMenu';
import getName from '../utils/NavigationName';
import { ROLES } from '../helpers/constants';
import { WorkerContext } from '../context/WorkerContext';
import { EmployerContext } from '../context/EmployerContext';
import { NotificationsContext } from '../context/NotificationsContext';
import EmptyList from './EmptyList';
import { TNotification, TNotificationsResponse } from '../api/NotificationsApi';
import { getNotificationCommonData } from '../helpers/NotificationsUtils';
import Modal from './Modal';

const { Header, Sider } = Layout;

type MenuItemProps = {
  type: IconType;
  urlPrefix?: string;
  messagesNumber?: number;
};

const menuMessages: { [key: string]: string } = {
  dashboard: 'mainMenuItems.dashboard',
  calendar: 'mainMenuItems.calendar',
  myWorkinn: 'mainMenuItems.myWorkinn',
  jobs: 'mainMenuItems.jobs',
  myOffers: 'mainMenuItems.myOffers',
  logout: 'mainMenuItems.logout',
  profile: 'mainMenuItems.account',
  chat: 'mainMenuItems.chat',
  candidateCatalogue: 'mainMenuItems.candidateCatalogue',
  companiesCatalogue: 'mainMenuItems.companiesCatalogue',
};

const MenuItem: React.FC<MenuItemProps> = ({ type, urlPrefix }) => {
  const history = useHistory();
  const isActive = urlPrefix
    ? history.location.pathname.toLowerCase().startsWith(urlPrefix.toLowerCase())
    : false;
  const { t } = useTranslation();
  return (
    <MenuItemWrapper
      isActive={isActive}
      onClick={(): void => {
        if (urlPrefix) {
          history.push(`${urlPrefix}`);
        }
      }}>
      <SvgDesktopIconWrapper isActive={isActive}>
        <SvgIcon type={type} />
      </SvgDesktopIconWrapper>
      <Text center message={t(menuMessages[type])} type="small" color="white" />
    </MenuItemWrapper>
  );
};

const SvgDesktopIconWrapper = styled.div<{ isActive: boolean }>`
  background: ${(props): string =>
    props.isActive ? 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 0)'};
  border-radius: 2rem;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuItemWrapper = styled.li<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 5rem; */
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding-left: 5px;
  padding-right: 5px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h6 {
    font-size: ${THEME.fontSize.small};
    color: ${(props): string => props.theme.color.white};
    text-align: center;
    line-height: 1.4;
  }
  :hover {
    cursor: pointer;
  }
`;

const MobileMenuItem: React.FC<MenuItemProps> = ({ type, urlPrefix, messagesNumber }) => {
  const history = useHistory();
  const isActive = urlPrefix
    ? history.location.pathname.toLowerCase().startsWith(urlPrefix.toLowerCase())
    : false;
  const { t } = useTranslation();
  return (
    <MobileMenuItemWrapper
      isActive={isActive}
      onClick={(): void => {
        if (urlPrefix) {
          history.push(`${urlPrefix}`);
        }
      }}>
      <SvgWrapper isActive={isActive}>
        <SvgIcon type={type} />
      </SvgWrapper>
      <Text center message={t(menuMessages[type])} type="small" color="white" />
      {messagesNumber && messagesNumber > 0 ? (
        <MobileMessagesNumberWrapper isActive={isActive}>
          {messagesNumber}
        </MobileMessagesNumberWrapper>
      ) : null}
    </MobileMenuItemWrapper>
  );
};

const MobileMessagesNumberWrapper = styled.div<{ isActive: boolean }>`
  position: absolute;
  width: 15px;
  height: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  top: ${props => (props.isActive ? '5px' : '12px')};
  right: ${props => (props.isActive ? '20px' : '1px')};
  border-radius: 250px;
  background: #ff683e;
  user-select: none;
  color: white;
  font-size: 10px;
  font-weight: 700;
`;

const MobileMenuItemWrapper = styled.li<{ isActive: boolean }>`
  position: relative;
  display: flex;
  justify-content: ${(props): string => (props.isActive ? 'flex-end' : 'center')};
  align-items: center;
  flex-direction: column;
  height: 100%;
  h6 {
    display: ${(props): string => (props.isActive ? 'block' : 'none')};
    font-size: ${THEME.fontSize.small};
    line-height: 1.4;
    color: ${(props): string => props.theme.color.white};
  }
  :hover {
    cursor: pointer;
  }
`;

const SvgWrapper = styled.div<{ isActive: boolean }>`
  background: ${(props): string =>
    props.isActive ? 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 0)'};
  border-radius: 2rem;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const onNotificationClick = (notification: TNotification, history: any) => {
  switch (notification.type) {
    case 'WORKER_VERIFICATION_REMINDER':
      return undefined;

    case 'MESSAGE_FROM_USER':
      return notification.meta?.chat?.chat_arn
        ? history.push(`/inbox/${encodeURIComponent(notification.meta?.chat?.chat_arn)}`)
        : undefined;

    case 'SETTLEMENT_REMINDER':
    case 'IMPROVE_SETTLEMENT_REMINDER':
    case 'RESIGNATION_REQUEST':
    case 'SETTLEMENT_TO_CONFIRMED':
    case 'CORRECTED_SETTLEMENT':
      return history.push(paths.myWorkinn);

    case 'JOB_OFFER_APPLICATION':
      return notification.meta?.worker_profile?.id
        ? history.push(`/workers/${notification.meta?.worker_profile?.id}`)
        : undefined;

    case 'MATCHING_WORKERS':
    case 'JOB_OFFER_RESIGNATION':
    case 'INVITATION_TO_WORK':
    case 'SEVEN_DAYS_BEFORE':
    case 'ONE_DAYS_BEFORE':
    case 'ACCEPTANCE_APPLICATION':
    case 'CANCELLATION_APPLICATION':
    case 'RESIGNATION_REQUEST_ACCEPT':
    case 'RESIGNATION_REQUEST_REJECT':
      return notification.meta?.job_offer?.id
        ? history.push(`/job-offers/${notification.meta?.job_offer?.id}`)
        : undefined;

    case 'MATCHING_JOB_OFFER':
      return history.push({
        pathname: `/job-offers/${notification.meta?.job_offer?.id}`,
        state: { matched: true },
      });
    case 'OPINION_FROM_WORKER':
    case 'OPINION_FROM_EMPLOYER':
      return history.push(paths.profile);
    default:
      return undefined;
  }
};

export const getDiffMessage = (diff: {
  days: number;
  hours: number;
  minutes: number;
}) => {
  if (diff.days !== 0) {
    return { value: diff.days, messageKey: 'notifications.daysAgo' };
  }
  if (diff.hours !== 0) {
    return { value: diff.hours, messageKey: 'notifications.hoursAgo' };
  }
  return { value: diff.minutes, messageKey: 'notifications.minutesAgo' };
};

const StyledLink = styled(Link)`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;
`;

const ChatMenu: React.FC = () => {
  const {
    getNotifications,
    notifications: notificationsResponse,
    setNotificationAsSeen,
    setNotificationDelete,
  } = useContext(NotificationsContext);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    getNotifications();
  }, []);

  const messagesNumber = notificationsResponse.filter(
    notification => notification.seen === false
  ).length;

  const currentLang = localStorage.getItem('i18nextLng')?.toUpperCase();

  const translatedProffession = (event: TNotification) =>
    event.meta?.job_offer?.speciality?.translations?.find(
      translation => translation.language_tag === currentLang
    )?.translation;

  const [activeTab, setActiveTab] = useState<string>('NOTIFICATIONS');
  const [messagesIds, setMessagesIds] = useState<string[]>([]);
  const [uncheckConfirmModal, setUncheckConfirmModal] = useState<boolean>(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState<boolean>(false);

  const handleAddId = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    notificationId: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setMessagesIds([...messagesIds, notificationId]);
  };

  const handleRemoveId = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    notificationId: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setMessagesIds(messagesIds.filter(id => id !== notificationId));
  };

  const handleIsChecked = (itemId: string) => messagesIds.includes(itemId);

  const handleCheckAll = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    notifications: TNotification[],
    clearArray: boolean
  ) => {
    e.stopPropagation();
    e.preventDefault();

    const allNotificationsIds = notifications.map(({ id }) => id);

    if (clearArray) {
      setMessagesIds([]);
    } else {
      setMessagesIds(allNotificationsIds);
    }
  };

  const returnNotifications = (notifications: TNotificationsResponse) => (
    <>
      {notifications.length === 0 ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '200px',
          }}>
          <EmptyList message={t('notifications.empty')} />
        </div>
      ) : (
        <>
          <ButtonAndIconWrapper>
            {notifications.length === messagesIds.length ? (
              <StyledPrimaryButton
                type="primary"
                onClick={e => handleCheckAll(e, notifications, true)}>
                {t('notifications.uncheckAll')}
              </StyledPrimaryButton>
            ) : (
              <StyledPrimaryButton
                type="primary"
                onClick={e => handleCheckAll(e, notifications, false)}>
                {t('notifications.checkAll')}
              </StyledPrimaryButton>
            )}
            {messagesIds.length > 0 && (
              <div>
                <Tooltip placement="bottom" title={t('inbox.markAsRead')}>
                  <StyledMailOutlined onClick={() => setUncheckConfirmModal(true)} />
                </Tooltip>
                <Tooltip placement="bottom" title={t('inbox.deleteSelected')}>
                  <StyledDeleteOutlined onClick={() => setDeleteConfirmModal(true)} />
                </Tooltip>
              </div>
            )}
            <Modal
              visible={uncheckConfirmModal}
              type="info"
              title={t('inbox.uncheckingConfirmInfo')}
              onOk={() => {
                setNotificationAsSeen(messagesIds);
                setUncheckConfirmModal(false);
                setMessagesIds([]);
              }}
              onCancel={() => {
                setUncheckConfirmModal(false);
                setMessagesIds([]);
              }}
              okMessage={t('global.confirm')}
            />
            <Modal
              visible={deleteConfirmModal}
              type="info"
              title={t('inbox.removingConfirmInfo')}
              onOk={() => {
                setNotificationDelete(messagesIds);
                setDeleteConfirmModal(false);
                setMessagesIds([]);
              }}
              onCancel={() => {
                setDeleteConfirmModal(false);
                setMessagesIds([]);
              }}
              okMessage={t('global.confirm')}
            />
          </ButtonAndIconWrapper>

          {notifications.map(notification => {
            const now = moment();
            const diff = moment.duration(now.diff(moment(notification.timestamp)));

            const diffMessage = getDiffMessage({
              hours: diff.hours(),
              minutes: diff.minutes(),
              days: diff.days(),
            });

            const avatar = (() => {
              switch (notification.type) {
                case 'JOB_OFFER_APPLICATION':
                case 'RESIGNATION_REQUEST':
                  return notification?.meta?.worker_profile?.avatar;

                case 'INVITATION_TO_WORK':
                case 'ACCEPTANCE_APPLICATION':
                  return notification?.meta?.employer_profile?.logo;

                case 'MESSAGE_FROM_USER':
                  return notification?.meta?.chat?.user?.avatar || undefined;

                default:
                  return undefined;
              }
            })();

            const { userName, workerName, employerName, messageCompanyName } =
              getNotificationCommonData(notification);

            const offerName = notification.meta?.job_offer
              ? t('notifications.offerTitle', {
                  speciality: translatedProffession(notification),
                  companyName: notification.meta?.job_offer.institution,
                })
              : '';

            return (
              <NotificationItem
                seen={notification.seen}
                onClick={() => {
                  onNotificationClick(notification, history);
                  if (!notification.seen) {
                    setNotificationAsSeen([notification.id]);
                  }
                }}>
                {avatar ? (
                  <ImageWrapper>
                    <LogoAvatar src={avatar} />
                  </ImageWrapper>
                ) : (
                  <ImageWrapper>
                    <SvgIcon type="workinNotificationLogo" />
                  </ImageWrapper>
                )}
                <NotificationContentWrapper>
                  <Text
                    message={t(`notifications.${notification.type}`, {
                      workerName,
                      offerName,
                      employerName,
                      userName,
                      companyName: messageCompanyName,
                    })}
                  />
                  <Text
                    message={t(diffMessage.messageKey, { number: diffMessage.value })}
                    type="small"
                  />
                </NotificationContentWrapper>
                <StyledCheckbox
                  onClick={e => {
                    if (handleIsChecked(notification.id)) {
                      handleRemoveId(e, notification.id);
                    } else {
                      handleAddId(e, notification.id);
                    }
                  }}
                  checked={handleIsChecked(notification.id)}
                />
              </NotificationItem>
            );
          })}
        </>
      )}
    </>
  );

  return (
    <ChatMenuWrapper>
      <Dropdown
        overlay={
          <NotificationsWrapper>
            <Tabs
              firstTab="NOTIFICATIONS"
              secondTab="MESSAGES"
              firstTabName={t('notifications.notificationTabTitle')}
              secondTabName={t('notifications.messagesTabTitle')}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              center
            />
            {activeTab === 'NOTIFICATIONS' ? (
              returnNotifications(
                notificationsResponse.filter(el => el.type !== 'MESSAGE_FROM_USER')
              )
            ) : (
              <>
                {returnNotifications(
                  notificationsResponse.filter(el => el.type === 'MESSAGE_FROM_USER')
                )}
                <StyledLink to="/inbox">
                  <Text
                    message={t('notifications.inboxOpen')}
                    type="medium"
                    color="primary"
                  />
                </StyledLink>
              </>
            )}
          </NotificationsWrapper>
        }
        trigger={['click']}>
        <SvgIcon type="greyChat" />
      </Dropdown>
      {messagesNumber > 0 ? (
        <ChatNumberWrapper>
          <Text message={`${messagesNumber}`} color="white" type="small" weight={700} />
        </ChatNumberWrapper>
      ) : null}
    </ChatMenuWrapper>
  );
};

const ButtonAndIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  background-color: ${THEME.color.primary};
  color: ${THEME.color.white};
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  margin-left: 5px;

  :hover {
    opacity: 0.6;
  }

  svg {
    width: 26px;
    height: 26px;
  }
`;

const StyledMailOutlined = styled(MailOutlined)`
  background-color: ${THEME.color.primary};
  color: ${THEME.color.white};
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }

  svg {
    width: 26px;
    height: 26px;
  }
`;

const StyledPrimaryButton = styled(Button)`
  margin: 10px;
  border-radius: 2rem;
  color: ${THEME.color.white};
  background-color: ${THEME.color.primary};
  border: none;
  font-weight: 600;

  :hover,
  :focus {
    color: ${THEME.color.white};
    background: ${THEME.color.primary};
    border-color: none;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked::after {
    border-radius: 50%;
  }
  .ant-checkbox-inner {
    width: 36px;
    height: 36px;
    border-radius: 50%;

    ::after {
      left: 34%;
    }
  }
`;

const LogoAvatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageWrapper = styled.div`
  border-radius: 30px;
  overflow: hidden;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: calc(100% - 40px);
`;

const NotificationItem = styled.div<{ seen: boolean }>`
  border-bottom: 1px solid ${props => props.theme.color.grey};
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 54px;
  opacity: ${props => (props.seen ? 0.4 : 1)};
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const NotificationsWrapper = styled.div`
  border-radius: 20px;
  width: 450px;
  padding-top: 10px;
  min-height: 200px;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  background: ${props => props.theme.color.white};
`;

const ChatMenuWrapper = styled.div`
  position: relative;
  margin-right: 30px;
  cursor: pointer;
`;

const ChatNumberWrapper = styled.div`
  position: absolute;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  display: flex;
  top: -5px;
  right: -12px;
  border-radius: 250px;
  background: #ff683e;
  user-select: none;
`;

const PanelLayout: React.FC = ({ ...props }) => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const { role, setUserRole, profileId } = useContext(ProfileContext);
  const { getFavorites } = useContext(FavoriteContext);

  const { data: workerData, getWorkerData } = useContext(WorkerContext);
  const { data: employerData, getEmployerData } = useContext(EmployerContext);

  useEffect(() => {
    if (!profileId) return;
    if (role === ROLES.Employer && employerData.company_name === '') {
      getEmployerData(profileId);
    } else if (role === ROLES.Worker && workerData.last_name === '') {
      getWorkerData(profileId);
    }
  }, [role, profileId]);

  useEffect(() => {
    if (!role) {
      setUserRole();
      getFavorites();
    }
  }, [role]);

  const { getNotifications, notifications } = useContext(NotificationsContext);

  useEffect(() => {
    moment.locale(localStorage.getItem('i18nextLng') || 'pl');
    getNotifications();
  }, []);

  const messagesNumber = notifications.filter(
    notification => notification.seen === false
  ).length;

  const { earnings, getEarnings } = useContext(WorkerContext);
  const { workers, getWorkers } = useContext(EmployerContext);

  const { salary } = earnings;
  const expenses = workers.salary;

  useEffect(() => {
    if (profileId) {
      getEarnings();
    }
    if (profileId && role === ROLES.Employer) {
      getWorkers();
    }
  }, [profileId]);

  return (
    <>
      {width > THEME.screenWidth.sm ? (
        <LayoutWrapper>
          <Layout>
            <StyledSider trigger={null} collapsible collapsed>
              <LogoWrapper>
                <SvgIcon type="logo" />
              </LogoWrapper>
              <StyledMenu mode="vertical">
                <MenuItem type="dashboard" urlPrefix={paths.dashboard} />
                {role === ROLES.Employer ? (
                  <MenuItem
                    type="candidateCatalogue"
                    urlPrefix={paths.employerAccountCandidates}
                  />
                ) : (
                  <MenuItem
                    type="companiesCatalogue"
                    urlPrefix={paths.userAccountCompanies}
                  />
                )}
                <MenuItem type="calendar" urlPrefix={paths.calendar} />
                <MenuItem type="myWorkinn" urlPrefix={paths.myWorkinn} />
                {role === ROLES.Worker && (
                  <MenuItem type="jobs" urlPrefix={paths.jobOffers} />
                )}
              </StyledMenu>
              <LogoutWrapper onClick={(): void => authContext.signOut(history)}>
                <MenuItem type="logout" />
              </LogoutWrapper>
            </StyledSider>
            <Layout>
              <StyledHeader>
                <MenuWrapper>
                  {history.location.pathname !== paths.dashboard ? (
                    <ArrowBack type="arrowBack" onClick={() => history.goBack()} />
                  ) : (
                    <div />
                  )}
                  <FlexRow>
                    <ChatMenu />
                    <UserMenu />
                  </FlexRow>
                </MenuWrapper>
              </StyledHeader>
              <ContentWrapper>{props.children}</ContentWrapper>
            </Layout>
          </Layout>
        </LayoutWrapper>
      ) : (
        <MobileLayoutWrapper>
          <Screen>
            <Navigator>
              {history.location.pathname !== paths.dashboard && (
                <MobileArrow type="arrowBackMobile" onClick={() => history.goBack()} />
              )}
              <MobileNavName>
                {history.location.pathname === paths.dashboard ? (
                  <FlexRow>
                    <DashbordBadgeWrapper>
                      <SvgIcon type="dashboardEarnings" />
                    </DashbordBadgeWrapper>
                    {role === ROLES.Employer ? (
                      <Text
                        message={t('dashboardProfile.expensesValue', {
                          value: expenses || 0,
                        })}
                        color="white"
                        type="medium"
                        marginBottom="0"
                      />
                    ) : (
                      <Text
                        message={t('dashboardProfile.earningsValue', {
                          value: salary || 0,
                        })}
                        color="white"
                        type="medium"
                        marginBottom="0"
                      />
                    )}
                  </FlexRow>
                ) : (
                  t(getName(history.location.pathname, role as ROLES))
                )}
              </MobileNavName>
              <div />
            </Navigator>
            <ChildrenWrapper>{props.children}</ChildrenWrapper>
          </Screen>
          <MobileStyledMenu mode="horizontal">
            <MobileMenuItem type="dashboard" urlPrefix={paths.dashboard} />
            {role === ROLES.Employer ? (
              <MobileMenuItem
                type="candidateCatalogue"
                urlPrefix={paths.employerAccountCandidates}
              />
            ) : (
              <MobileMenuItem
                type="companiesCatalogue"
                urlPrefix={paths.userAccountCompanies}
              />
            )}
            <MobileMenuItem type="calendar" urlPrefix={paths.calendar} />
            <MobileMenuItem type="myWorkinn" urlPrefix={paths.myWorkinn} />
            <MobileMenuItem
              type="chat"
              urlPrefix={paths.mobileNotifications}
              messagesNumber={messagesNumber}
            />
            <MobileMenuItem
              type="profile"
              urlPrefix={
                role === ROLES.Worker ? paths.userAccount : paths.employerAccount
              }
            />
            {role === ROLES.Worker && (
              <MobileMenuItem type="jobs" urlPrefix={paths.jobOffers} />
            )}
          </MobileStyledMenu>
        </MobileLayoutWrapper>
      )}
    </>
  );
};

const DashbordBadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.625rem;
  height: 2.625rem;
  margin-right: 1rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.15);
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MobileNavName = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 18px;
  font-weight: 600;
`;

const MobileArrow = styled(SvgIcon)`
  margin-left: 1rem;
  cursor: pointer;
  padding: 1rem;
`;

const ContentWrapper = styled(Layout.Content)`
  max-width: 1344px;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 3.5rem 5rem 3.5rem;
  width: 100%;
`;

const ArrowBack = styled(SvgIcon)`
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  width: 100%;
  max-width: 1344px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 3.5rem 0 3.5rem;
  align-items: center;
`;

const Screen = styled.div`
  height: calc(100% - 66px);
`;

const ChildrenWrapper = styled.div`
  background: ${THEME.color.white};
  min-height: calc(100vh - 146px);
  height: calc(100% - 146px);
  border-top-left-radius: 2.1875rem;
  border-top-right-radius: 2.1875rem;
  display: flex;
  /* align-items: center; */
  justify-content: center;
`;

const Navigator = styled.div`
  height: 80px;
  color: ${THEME.color.white};
  display: flex;
  justify-content: start;
  align-items: center;
`;

const MobileStyledMenu = styled(Menu)`
  background: ${THEME.color.primary};
  border: 0px;
  display: flex;
  position: sticky;
  bottom: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  z-index: 2;
`;

const MobileLayoutWrapper = styled.div`
  background: ${THEME.color.primary};
`;

const LayoutWrapper = styled.div`
  #components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: ${THEME.fontSize.big};
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }

  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }

  .site-layout .site-layout-background {
    background: ${THEME.color.white};
  }
  .ant-layout-sider-children {
    height: 100vh;
  }
`;

const StyledHeader = styled(Header)`
  background: ${THEME.color.white};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 0;
  height: 4.75rem;
  display: flex;
  justify-content: flex-end;
  position: sticky !important;
  top: 0;
  z-index: 100;
`;

const LogoWrapper = styled.div`
  margin-top: 2.375rem;
  display: flex;
  justify-content: center;
`;

const LogoutWrapper = styled.div`
  margin-bottom: 6.25rem;
  display: flex;
  justify-content: center;
`;

const StyledMenu = styled(Menu)`
  background: ${THEME.color.primary};
  border: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledSider = styled(Sider)`
  background: ${(props): string => props.theme.color.primary};
  max-width: 6rem;
  min-width: 6rem;
  width: 6rem;
  position: sticky !important;
  top: 0;
  height: 100vh;
  overflow: auto;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export default PanelLayout;
