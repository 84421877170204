import moment from 'moment';
import { dateTimeFormat } from '../helpers/constants';
import { createUrl } from '../helpers/functions';
import HttpClient from '../utils/http-client';
import { environmentConfig } from '../Config';
import { SpecialitieType } from '../context/WorkerContext';

const PATHS = {
  notifications: '/v1/notifications/',
  notificationsSettings: '/v1/notifications/settings/',
  notificationSeen: '/v1/notifications/seen/',
  notificationDelete: '/v1/notifications/delete/',
};

export type TNotification = {
  id: string;
  description: string;
  title: string;
  type: TNotificationKey;
  timestamp: string;
  seen: boolean;
  recipient_id: string;
  meta: {
    worker_profile?: {
      avatar: string;
      first_name: string;
      id: number;
      last_name: string;
    };
    job_offer?: {
      company: string;
      id: number;
      speciality: SpecialitieType;
      institution?: string;
    };
    chat?: {
      chat_arn?: string;
      user?: {
        first_name: string;
        id: number;
        last_name: string;
        avatar: string;
        company_name: string;
      };
    };
    employer_profile?: {
      logo: string;
      company_name: string;
    };
  };
};

export type TNotificationKey =
  | 'WORKER_VERIFICATION_REMINDER'
  | 'MATCHING_WORKERS'
  | 'SETTLEMENT_REMINDER'
  | 'IMPROVE_SETTLEMENT_REMINDER'
  | 'JOB_OFFER_APPLICATION'
  | 'JOB_OFFER_RESIGNATION'
  | 'MESSAGE_FROM_USER'
  | 'OPINION_FROM_WORKER'
  | 'RESIGNATION_REQUEST'
  | 'MATCHING_JOB_OFFER'
  | 'INVITATION_TO_WORK'
  | 'SETTLEMENT_TO_CONFIRMED'
  | 'CORRECTED_SETTLEMENT'
  | 'SEVEN_DAYS_BEFORE'
  | 'ONE_DAYS_BEFORE'
  | 'ACCEPTANCE_APPLICATION'
  | 'CANCELLATION_APPLICATION'
  | 'OPINION_FROM_EMPLOYER'
  | 'RESIGNATION_REQUEST_ACCEPT'
  | 'RESIGNATION_REQUEST_REJECT';

export type TNotificationsResponse = Array<TNotification>;

export type TNotificationsSettings = Array<{
  key: string;
  values: {
    email: boolean;
    system: boolean;
  };
}>;

class NotificationsApi extends HttpClient {
  constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public getNotifications = async () =>
    this.instance.get<any, TNotificationsResponse>(PATHS.notifications);

  public getNotificationsSettings = async () =>
    this.instance.get<any, TNotificationsSettings>(PATHS.notificationsSettings);

  public saveNotificationsSettings = async (
    credentials: TNotificationsSettings
  ): Promise<TNotificationsSettings> =>
    this.instance.post<TNotificationsSettings, TNotificationsSettings>(
      PATHS.notificationsSettings,
      credentials
    );

  public setNotificationAsSeen = async (
    notificationIds: string[]
  ): Promise<TNotificationsSettings> =>
    this.instance.post<TNotificationsSettings, TNotificationsSettings>(
      PATHS.notificationSeen,
      { notification_ids: notificationIds }
    );

  public setNotificationDelete = async (
    notificationIds: string[]
  ): Promise<TNotificationsSettings> =>
    this.instance.post<TNotificationsSettings, TNotificationsSettings>(
      PATHS.notificationDelete,
      { notification_ids: notificationIds }
    );
}

export default NotificationsApi;
