import moment from 'moment';
import { dateTimeFormat } from '../helpers/constants';
import { createUrl } from '../helpers/functions';
import HttpClient from '../utils/http-client';
import { environmentConfig } from '../Config';
import { SpecialitieType, TranslationSkills } from '../context/WorkerContext';

type ID = string | number;

const PATHS = {
  offers: '/v1/offers/',
  offer: (id: string) => `/v1/offers/${id}/`,
  inviteWorker: (offerId: number | string, workerId: number) =>
    `/v1/offers/${offerId}/invite/${workerId}/`,
  myOffers: '/v1/offers/my/',
  apply: (id: number) => `/v1/offers/${id}/apply/`,
  resignation: (offerId: string, userId: string) =>
    `v1/offers/${offerId}/applications/${userId}/`,
  resignationRequest: (offerId: string, userId: string) =>
    `v1/offers/${offerId}/resignation_request/`,
  confirmResignation: (offerId: ID, userId: ID) =>
    `v1/offers/${offerId}/confirm_resignation/${userId}/`,
  getResignationRequest: (offerId: ID, userId: ID) =>
    `v1/offers/${offerId}/resignation/${userId}/`,
  getContract: (offerId: ID, userId: ID) => `v1/offers/${offerId}/pdf/${userId}/`,
  getContracts: (offerId: ID) => `v1/offers/${offerId}/pdf/`,
  sendBroadcastMessage: (offerId: ID) => `v1/offers/${offerId}/broadcast_message/`,
};

export type TWorker = {
  worker_profile: {
    avatar: string;
    id: number;
    first_name: string;
    rating: null;
    rating_count: number;
    last_name: string;
  };
  status: TWorkerStatus;
  chime_chat?: string;
};

export enum TWorkerStatus {
  candidate = 'CANDIDATE',
  //u góry sprawdzone
  confirmed = 'CONFIRMED',
  //
  forSettlement = 'FOR_SETTLEMENT',
  settled = 'SETTLED',
  unsettled = 'UNSETTLED',
  notCome = 'WORKER_NOT_COME',
  settlementToImprove = 'SETTLEMENT_TO_IMPROVED',
  settlementSent = 'SETTLEMENT_SENT',
  executed = 'EXECUTED',
  rejected = 'REJECTED',
  resignationRequest = 'RESIGNATION_REQUEST',
  settlementRejected = 'SETTLEMENT_REJECTED',
}

export type TOffer = {
  id: number;
  title: string;
  event_dates: Array<Array<string>>;
  location: {
    city: string;
    address: string;
  };
  speciality: SpecialitieType;
  abilities: TranslationSkills[];
  contents: string;
  requirements: string;
  hourly_rate: string;
  number_of_vacancies: number;
  number_of_workers_needed?: number;
  compatible: boolean;
  institution?: string;
  applied: boolean;
  can_application: {
    status: boolean;
    reasons?: Array<string>;
  };
  employer_profile: number;
  created_at: string;
  logo?: string;
  company_name?: string;
  status?: string;
  workers?: Array<TWorker>;
  is_active?: boolean;
};

export type TData = {
  hourly_rate: number;
  number_of_vacancies: number;
  dateTimeRange: Array<{ start: string; end: string }>;
  location: string | { city: string; address: string; zip_code: string };
  speciality: string;
  abilities: TranslationSkills[];
  contents: string;
  sex?: string;
  requirements?: string;
};

export type TOffersResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<TOffer>;
};

export type TOffersQueryParams = {
  search?: string;
  ordering?: string;
  page?: number;
  page_size?: number;
  rate_min?: number;
  rate_max?: number;
  speciality?: Array<number>;

  distance?: string;
  city_lng?: number;
  city_lat?: number;
  city?: string;
  filter_by?: 'MATCHED' | 'LATEST' | 'POPULAR';
  start_date?: string;
  end_date?: string;
};

export type TMyOffersQueryParams = {
  page?: number;
  page_size?: number;
  status?: string | Array<string>;
};

class OffersApi extends HttpClient {
  constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public add = async (
    data: TData,
    successCallback: () => void,
    errorCallback: (error: any) => void
  ): Promise<void> => {
    try {
      await this.instance.post(PATHS.offers, {
        ...data,
        event_dates: data.dateTimeRange.map(el => [
          moment(el.start, dateTimeFormat),
          moment(el.end, dateTimeFormat),
        ]),
        location: data.location,
      }); //hack, to change later (geocoding)
      successCallback();
    } catch (err: any) {
      errorCallback(err?.response?.data);
    }
  };

  public get = async (queryParams?: TOffersQueryParams): Promise<TOffersResponse> =>
    this.instance.get<any, TOffersResponse>(createUrl(PATHS.offers, queryParams));

  public getSingleOffer = async (id: string) =>
    this.instance.get<any, TOffer & { employer_profile: { id: number; name: string } }>(
      PATHS.offer(id)
    );

  public inviteWorker = async (offerId: number | string, workerId: number) =>
    this.instance.post(PATHS.inviteWorker(offerId, workerId));

  public getMyOffers = async (queryParams?: any) =>
    this.instance.get<any, TOffersResponse>(createUrl(PATHS.myOffers, queryParams));

  public apply = async (id: number) => this.instance.post(PATHS.apply(id));

  public resignationRequest = async (
    offerId: string,
    userId: string,
    description: string
  ) => this.instance.post(PATHS.resignationRequest(offerId, userId), { description });

  public sendBroadcastMessage = async (offerId: string, message: string) =>
    this.instance.post(PATHS.sendBroadcastMessage(offerId), { content: message });

  public resignation = async (offerId: string, userId: string, status?: string) =>
    this.instance.put(PATHS.resignation(offerId, userId), {
      status: status || 'REJECTED',
    });

  public confirmResignation = async (
    offerId: ID,
    userId: ID,
    status: 'CONFIRMED' | 'REJECTED'
  ) => this.instance.put(PATHS.confirmResignation(offerId, userId), { status });

  public getResignationRequest = async (offerId: ID, userId: ID) =>
    this.instance.get<any, { description: string }>(
      PATHS.getResignationRequest(offerId, userId)
    );

  public getContract = async (offerId: ID, userId: ID) =>
    this.instance.get<any, { file_key: string }>(PATHS.getContract(offerId, userId));

  public getContracts = async (offerId: ID) =>
    this.instance.get<any, any>(PATHS.getContracts(offerId));
}

export default OffersApi;
