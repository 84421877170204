import styled from 'styled-components';
import Text from '../Text';
import theme from '../../utils/theme';
import SvgIcon from '../SvgIcon';

type Props = {
  hasNotWorkinExperience?: boolean;
  firstTab: string;
  firstTabName: string;
  secondTab: string;
  secondTabName: string;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  center?: boolean;
};

const Tabs: React.FC<Props> = ({
  hasNotWorkinExperience,
  firstTab,
  firstTabName,
  secondTab,
  secondTabName,
  activeTab,
  setActiveTab,
  center,
}) => (
  <TabsWrapper onClick={e => e.preventDefault()} center={center}>
    <Tab
      onClick={() => setActiveTab(firstTab)}
      activeTab={activeTab === firstTab}
      onMouseEnter={() => setActiveTab(firstTab)}>
      <StyledText
        message={firstTabName}
        weight={600}
        type="medium"
        activeTab={activeTab === firstTab}
      />
      <Line activeTab={activeTab === firstTab} tab={firstTab} firstTab={firstTab} />
    </Tab>
    <Tab
      onClick={() => setActiveTab(secondTab)}
      activeTab={activeTab === secondTab}
      onMouseEnter={() => setActiveTab(secondTab)}>
      <Flex>
        <StyledText
          message={secondTabName}
          weight={600}
          type="medium"
          activeTab={activeTab === secondTab}
        />
        {hasNotWorkinExperience && <SvgIcon type="smallWork" />}
      </Flex>
      <Line activeTab={activeTab === secondTab} tab={secondTab} firstTab={firstTab} />
    </Tab>
  </TabsWrapper>
);

const Flex = styled.div`
  display: flex;
  svg {
    margin-left: 0.5rem;
    margin-right: -1rem;
  }
`;

const StyledText = styled(Text)<{ activeTab: boolean }>`
  display: inline-block;
  margin-bottom: 0.5em;
  h4 {
    color: ${props => (props.activeTab ? theme.color.primary : theme.color.grey)};
    @media (${props => props.theme.screenMaxWidth.sm}) {
      font-size: 14px;
    }
  }
`;

const Line = styled.div<{ activeTab: boolean; tab: string; firstTab: string }>`
  width: calc(100% + 1.75rem);
  border-bottom: solid 3px
    ${props => (props.activeTab ? theme.color.primary : theme.color.grey)};
  border-radius: ${props =>
    props.tab === props.firstTab ? '0.2rem 0 0 0.2rem' : '0 0.2rem 0.2rem 0'};
`;

const Tab = styled.div<{ activeTab: boolean }>`
  :not(:first-of-type) {
    margin-left: 1.75rem;
  }
  cursor: pointer;

  :hover {
    opacity: ${props => (props.activeTab ? '1' : '0.8')};
  }
`;

const TabsWrapper = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.center ? 'center' : 'start')};
`;

export default Tabs;
