import { ROLES } from '../helpers/constants';
import paths from '../paths.json';

const getName = (location: string, role?: ROLES): string => {
  if (location === paths.mobileNotifications) {
    return 'mobileLocation.messages';
  }
  if (location === paths.mainPage) {
    return 'mobileLocation.mainPage';
  }
  if (location.startsWith(paths.profile)) {
    return role === ROLES.Employer
      ? 'mobileLocation.employerProfile'
      : 'mobileLocation.workerProfile';
  }
  if (location.startsWith(paths.dashboard)) {
    return 'mobileLocation.dashboard';
  }

  if (location.startsWith(paths.addOffer)) {
    return 'mobileLocation.addOffer';
  }
  if (location.startsWith(paths.calendar)) {
    return 'mobileLocation.calendar';
  }

  if (location.startsWith('/employers')) {
    return 'mobileLocation.companyProfile';
  }

  if (location.startsWith('/workers')) {
    return 'mobileLocation.candidateProfile';
  }

  if (location.startsWith('/employer-account/watched')) {
    return 'mobileLocation.watched';
  }

  if (location.startsWith('/user-account/password-change')) {
    return 'mobileLocation.passwordChange';
  }

  if (location.startsWith('/user-account/email-change')) {
    return 'mobileLocation.emailChange';
  }

  if (location.startsWith('/user-account/watched')) {
    return 'mobileLocation.watched';
  }

  if (location.startsWith('/user-account/language-settings')) {
    return 'mobileLocation.languageSettings';
  }

  if (location.startsWith('/employer-account/language-settings')) {
    return 'mobileLocation.languageSettings';
  }

  if (location.startsWith('/user-account/notification-settings')) {
    return 'mobileLocation.notificationsSettings';
  }

  if (location.startsWith('/employer-account/notification-settings')) {
    return 'mobileLocation.notificationsSettings';
  }

  if (location.startsWith('/user-account/deactivation-account')) {
    return 'mobileLocation.deactivationAccount';
  }

  if (location.startsWith('/employer-account/deactivation-account')) {
    return 'mobileLocation.deactivationAccount';
  }

  if (location.startsWith(paths.jobOffers) && role === ROLES.Employer) {
    return 'mobileLocation.myOffers';
  }

  if (location.startsWith(paths.jobOffers) && role === ROLES.Worker) {
    return 'mobileLocation.jobOffers';
  }

  if (location.startsWith('/job-offers/')) {
    return 'mobileLocation.offerDetails';
  }

  if (location.startsWith('/employer-account/email-change')) {
    return 'mobileLocation.emailChange';
  }

  if (location.startsWith('/employer-account/password-change')) {
    return 'mobileLocation.passwordChange';
  }

  if (location.startsWith('/employer-account/settings')) {
    return 'mobileLocation.settings';
  }

  if (location.startsWith('/employer-account/candidate-catalogue')) {
    return 'mobileLocation.candidateCatalogue';
  }

  if (location.startsWith('/employer-account')) {
    return 'mobileLocation.account';
  }

  if (location.startsWith('/user-account/settings')) {
    return 'mobileLocation.settings';
  }

  if (location.startsWith('/user-account')) {
    return 'mobileLocation.account';
  }

  if (location.startsWith('/my-workin')) {
    return 'mobileLocation.myWorkinn';
  }
  return 'mobileLocation.default';
};

export default getName;
