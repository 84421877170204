import { createContext, useState } from 'react';
import EmployerCalendarApi from '../api/EmployerCalendarApi';
import { TOffer } from './OffersContext';

export type TEmployerCalendarContextState = {
  loading: boolean;
  offers: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Array<TOffer>;
  };
  getOffers: () => void;
};

export const employerCalendarContextDefaultState: TEmployerCalendarContextState = {
  loading: false,
  offers: { count: 0, next: null, previous: null, results: [] },
  getOffers: () => {},
};

export const EmployerCalendarContext = createContext<TEmployerCalendarContextState>(
  employerCalendarContextDefaultState
);

const api = new EmployerCalendarApi();

const EmployerCalendarContextProvider: React.FC = ({ children }) => {
  const [offers, setOffers] = useState<TEmployerCalendarContextState['offers']>(
    employerCalendarContextDefaultState.offers
  );

  const [loading, setLoading] = useState<TEmployerCalendarContextState['loading']>(
    employerCalendarContextDefaultState.loading
  );

  const getOffers = async () => {
    setLoading(true);
    const offersData = await api.getMyOffers();
    setOffers(offersData);
    setLoading(false);
  };

  return (
    <EmployerCalendarContext.Provider
      value={{
        loading,
        offers,
        getOffers,
      }}>
      {children}
    </EmployerCalendarContext.Provider>
  );
};

export default EmployerCalendarContextProvider;
