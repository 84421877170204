import { createContext, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UserAccountApi from '../api/account/UserAccount';
import customNotification from '../components/notification';
import { Experience } from '../helpers/professionsAndSkills';
import { getErrorFromResponse } from '../helpers/functions';
import { WorkerContext, TData } from './WorkerContext';
import { PhonePrefix } from './UserDetailsFormContext';
import { MODEL_ID } from '../helpers/constants';

export interface PersonalData {
  description: string;
  avatar: string;
  name: string;
  surname: string;
  pesel: string;
  nip: string;
  street: string;
  postalCode: string;
  city: string;
  phone: string;
  building_number: string;
  phone_country_code?: PhonePrefix;
  birthDate: string;
  sex: string | boolean;
  country: string;
  voivodeship: string;
  careers: number[];
  abilities: number[];
  weight: string;
  height: string;
  hips: string;
  waist: string;
  chest: string;
}

interface QuestionnaireData {
  employedContractInAnotherEstablishment: string | boolean;
  employedContractWageHigherThanTheMinimum: string | boolean;
  contractOfMandateInAnotherEstablishment: string | boolean;
  contractOfMandateWageHigherThanMinimum: string | boolean;
  runningABusiness: string | boolean;
  disability: string | boolean;
  student: string | boolean;
  retired: string | boolean;
  pensioner: string | boolean;
}

interface BillingData {
  usName: string;
  usAddressPartOne: string;
  usAddressPartTwo: string;
  city: string;
  postalCode: string;
  iban: string;
  country: string;
}

export type UserAccountContextState = {
  personalData: PersonalData;
  experience: Array<Experience>;
  questionnaireData: QuestionnaireData;
  voluntarySocialSecurity: string | boolean;
  billingData: BillingData;
  loading: boolean;
  loadData: (id: string) => void;
  setPersonalData: (personalData: PersonalData) => void;
  setExperience: (experience: Array<Experience>) => void;
  clearContext: () => void;
  savePersonalData: (id: string | number, personalData: PersonalData) => void;
  saveExperience: (id: string | number, experience: Array<Experience>) => void;
  saveQuestionaire: (id: string | number, questionaireData: QuestionnaireData) => void;
  saveZusData: (id: string | number, voluntarySocialSecurity: string | boolean) => void;
  saveBillingData: (id: string | number, billingData: BillingData) => void;
};

const contextDefaultValues: UserAccountContextState = {
  personalData: {
    description: '',
    avatar: '',
    name: '',
    surname: '',
    pesel: '',
    nip: '',
    street: '',
    postalCode: '',
    city: '',
    phone: '',
    birthDate: '',
    sex: '',
    country: '',
    voivodeship: '',
    phone_country_code: PhonePrefix.POLAND_PHONE_PREFIX,
    building_number: '',
    careers: [],
    abilities: [],
    weight: '',
    height: '',
    hips: '',
    waist: '',
    chest: '',
  },
  billingData: {
    usName: '',
    usAddressPartOne: '',
    usAddressPartTwo: '',
    city: '',
    postalCode: '',
    iban: '',
    country: '',
  },
  voluntarySocialSecurity: '',
  questionnaireData: {
    employedContractInAnotherEstablishment: '',
    employedContractWageHigherThanTheMinimum: '',
    contractOfMandateInAnotherEstablishment: '',
    contractOfMandateWageHigherThanMinimum: '',
    runningABusiness: '',
    disability: '',
    student: '',
    retired: '',
    pensioner: '',
  },
  experience: [],
  loading: false,
  loadData: () => {},
  setPersonalData: () => {},
  setExperience: () => {},
  savePersonalData: () => {},
  saveExperience: () => {},
  saveQuestionaire: () => {},
  saveZusData: () => {},
  saveBillingData: () => {},
  clearContext: () => {},
};

const booleanFromOption = (value: string | boolean) =>
  typeof value === 'boolean' ? value : false;

export const UserAccountContext =
  createContext<UserAccountContextState>(contextDefaultValues);

const UserAccountContextProvider: React.FC = ({ children }) => {
  const { data: workerData, setData: setWorkerData } = useContext(WorkerContext);
  const [personalData, setPersonalData] = useState<PersonalData>(
    contextDefaultValues.personalData
  );
  const [experience, setExperience] = useState<Array<Experience>>(
    contextDefaultValues.experience
  );
  const [questionnaireData, setQuestionnaireData] = useState<QuestionnaireData>(
    contextDefaultValues.questionnaireData
  );
  const [loading, setLoading] = useState<boolean>(contextDefaultValues.loading);
  const [avatarHelper, setAvatarHelper] = useState<string>('');
  const [billingData, setBillingData] = useState<BillingData>(
    contextDefaultValues.billingData
  );
  const [voluntarySocialSecurity, setVoluntarySocialSecurity] = useState<
    UserAccountContextState['voluntarySocialSecurity']
  >(contextDefaultValues.voluntarySocialSecurity);

  const { t } = useTranslation();

  const clearContext = () => {
    setPersonalData(contextDefaultValues.personalData);
  };

  const userAccountApi = new UserAccountApi();

  const savePersonalData = (id: string | number, data: PersonalData) => {
    const credentials = {
      description: data.description ? data.description : '',
      avatar: avatarHelper === data.avatar ? undefined : data.avatar,
      careers: data.careers,
      abilities: data.abilities,
      first_name: data.name,
      last_name: data.surname,
      date_of_birth: data.birthDate,
      sex: booleanFromOption(data.sex) ? 'FEMALE' : 'MALE',
      street: data.street,
      city: data.city,
      zip_code: data.postalCode,
      voivodeship: data.voivodeship,
      country: data.country,
      phone: data.phone_country_code + data.phone,
      dimensions: data.careers.includes(MODEL_ID)
        ? {
            weight: Number(data.weight),
            height: Number(data.height),
            hips: Number(data.hips),
            waist: Number(data.waist),
            chest: Number(data.chest),
          }
        : null,
    };
    userAccountApi
      .save(id, credentials)
      .then((res: TData) => {
        setWorkerData({ ...workerData, ...res });
        customNotification({ type: 'success', description: t('global.saveSuccess') });
      })
      .catch(err => {
        const details: Record<string, any> = err?.response?.data;
        const error = getErrorFromResponse(details?.errors[0]);
        customNotification({
          type: 'error',
          description: t(
            `userDetailsForm.errors.${error.field !== null ? error.field + '_' : ''}${
              error.code
            }`
          ),
        });
      });
  };
  const saveQuestionaire = (id: string | number, data: QuestionnaireData) => {
    const credentials = {
      employment_declaration: [
        {
          key: 'EMPLOYED_CONTRACT_IN_ANOTHER_ESTABLISHMENT',
          value: booleanFromOption(data.employedContractInAnotherEstablishment),
        },
        {
          key: 'EMPLOYED_CONTRACT_WAGE_HIGHER_THAN_THE_MINIMUM',
          value: booleanFromOption(data.employedContractWageHigherThanTheMinimum),
        },
        {
          key: 'CONTRACT_OF_MANDATE_IN_ANOTHER_ESTABLISHMENT',
          value: booleanFromOption(data.contractOfMandateInAnotherEstablishment),
        },
        {
          key: 'CONTRACT_OF_MANDATE_WAGE_HIGHER_THAN_MINIMUM',
          value: booleanFromOption(data.contractOfMandateWageHigherThanMinimum),
        },
        {
          key: 'RUNNING_A_BUSINESS',
          value: booleanFromOption(data.runningABusiness),
        },
        { key: 'DISABILITY', value: booleanFromOption(data.disability) },
        { key: 'STUDENT', value: booleanFromOption(data.student) },
        { key: 'RETIRED', value: booleanFromOption(data.retired) },
        { key: 'PENSIONER', value: booleanFromOption(data.pensioner) },
      ],
    };
    userAccountApi
      .save(id, credentials)
      .then(() =>
        customNotification({ type: 'success', description: t('global.saveSuccess') })
      )
      .catch(err => {
        const details: Record<string, any> = err?.response?.data;
        const error = getErrorFromResponse(details?.errors[0]);
        customNotification({
          type: 'error',
          description: t(
            `userDetailsForm.errors.${error.field !== null ? error.field + '_' : ''}${
              error.code
            }`
          ),
        });
      });
  };

  const saveExperience = (id: string | number, data: Array<Experience>) => {
    const credentials = {
      experience: data,
    };
    userAccountApi
      .save(id, credentials)
      .then(() =>
        customNotification({ type: 'success', description: t('global.saveSuccess') })
      )
      .catch(err => {
        const details: Record<string, any> = err?.response?.data;
        const error = getErrorFromResponse(details?.errors[0]);
        customNotification({
          type: 'error',
          description: t(
            `userDetailsForm.errors.${error.field !== null ? error.field + '_' : ''}${
              error.code
            }`
          ),
        });
      });
  };

  const saveZusData = (id: string | number, data: string | boolean) => {
    const credentials = {
      voluntary_social_security: booleanFromOption(data),
    };
    userAccountApi
      .save(id, credentials)
      .then(() =>
        customNotification({ type: 'success', description: t('global.saveSuccess') })
      )
      .catch(err => {
        const details: Record<string, any> = err?.response?.data;
        const error = getErrorFromResponse(details?.errors[0]);
        customNotification({
          type: 'error',
          description: t(
            `userDetailsForm.errors.${error.field !== null ? error.field + '_' : ''}${
              error.code
            }`
          ),
        });
      });
  };

  const saveBillingData = (id: string | number, data: BillingData) => {
    const credentials = {
      bank_account_number: data.iban,
      tax_office: {
        name: data.usName,
        address_line_1: data.usAddressPartOne,
        address_line_2: data.usAddressPartTwo,
        city: data.city,
        country: data.country,
        zip_code: data.postalCode,
      },
    };
    userAccountApi
      .save(id, credentials)
      .then(() =>
        customNotification({ type: 'success', description: t('global.saveSuccess') })
      )
      .catch(err => {
        const details: Record<string, any> = err?.response?.data;
        const error = getErrorFromResponse(details?.errors[0]);
        customNotification({
          type: 'error',
          description: t(
            `userDetailsForm.errors.${error.field !== null ? error.field + '_' : ''}${
              error.code
            }`
          ),
        });
      });
  };

  const loadData = async (id: string | number) => {
    setLoading(true);
    try {
      const userData = await userAccountApi.load(id);
      setPersonalData(state => ({
        description: userData.description,
        avatar: userData.avatar,
        name: userData.first_name,
        surname: userData.last_name,
        pesel: userData.PESEL,
        nip: userData.NIP,
        street: userData.street,
        postalCode: userData.zip_code,
        city: userData.city,
        phone: userData.phone,
        phone_country_code: userData.phone_country_code,
        building_number: userData.building_number,
        birthDate: userData.date_of_birth,
        sex: userData.sex === 'FEMALE',
        country: userData.country,
        voivodeship: userData.voivodeship,
        careers: userData.careers,
        abilities: userData.abilities,
        weight: userData.dimensions?.weight,
        height: userData.dimensions?.height,
        hips: userData.dimensions?.hips,
        waist: userData.dimensions?.waist,
        chest: userData.dimensions?.chest,
      }));
      setAvatarHelper(state => userData.avatar);
      setExperience(userData.experience.filter(element => !element.is_workinn));
      setQuestionnaireData({
        employedContractInAnotherEstablishment:
          userData.employment_declaration.find(
            element => element.key === 'EMPLOYED_CONTRACT_IN_ANOTHER_ESTABLISHMENT'
          )?.value || false,
        employedContractWageHigherThanTheMinimum:
          userData.employment_declaration.find(
            element => element.key === 'EMPLOYED_CONTRACT_WAGE_HIGHER_THAN_THE_MINIMUM'
          )?.value || false,
        contractOfMandateInAnotherEstablishment:
          userData.employment_declaration.find(
            element => element.key === 'CONTRACT_OF_MANDATE_IN_ANOTHER_ESTABLISHMENT'
          )?.value || false,
        contractOfMandateWageHigherThanMinimum:
          userData.employment_declaration.find(
            element => element.key === 'CONTRACT_OF_MANDATE_WAGE_HIGHER_THAN_MINIMUM'
          )?.value || false,
        runningABusiness:
          userData.employment_declaration.find(
            element => element.key === 'RUNNING_A_BUSINESS'
          )?.value || false,
        disability:
          userData.employment_declaration.find(element => element.key === 'DISABILITY')
            ?.value || false,
        student:
          userData.employment_declaration.find(element => element.key === 'STUDENT')
            ?.value || false,
        retired:
          userData.employment_declaration.find(element => element.key === 'RETIRED')
            ?.value || false,
        pensioner:
          userData.employment_declaration.find(element => element.key === 'PENSIONER')
            ?.value || false,
      });
      setVoluntarySocialSecurity(userData.voluntary_social_security);
      setBillingData({
        usName: userData.tax_office.name,
        usAddressPartOne: userData.tax_office.address_line_1,
        usAddressPartTwo: userData.tax_office.address_line_2,
        city: userData.tax_office.city,
        postalCode: userData.tax_office.zip_code,
        iban: userData.bank_account_number,
        country: userData.country,
      });
    } catch (err: any) {
      if (err?.response) {
        customNotification({ type: 'error', description: t('global.loadDataError') });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserAccountContext.Provider
      value={{
        personalData,
        billingData,
        questionnaireData,
        experience,
        voluntarySocialSecurity,
        loading,
        loadData,
        setPersonalData,
        setExperience,
        clearContext,
        savePersonalData,
        saveBillingData,
        saveExperience,
        saveQuestionaire,
        saveZusData,
      }}>
      {children}
    </UserAccountContext.Provider>
  );
};

export default UserAccountContextProvider;
