import './App.css';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import plPL from 'antd/lib/locale/pl_PL';
import enUS from 'antd/lib/locale/en_US';
import theme from './utils/theme';
import PathRouter from './PathRouter';
import AuthContextProvider from './context/AuthContext';
import UserDetailsFormContextProvider from './context/UserDetailsFormContext';
import OffersContextProvider from './context/OffersContext';
import EmployerDetailsFormContextProvider from './context/EmployerDetailsFormContext';
import ProfileContextProvider from './context/ProfileContext';
import WorkerContextProvider from './context/WorkerContext';
import EmployerContextProvider from './context/EmployerContext';
import UserAccountContextProvider from './context/UserAccountContext';
import EmployerAccountContextProvider from './context/EmployerAccountContext';
import FavoriteContextProvider from './context/FavoritesContext';
import CompaniesContextProvider from './context/CompaniesContext';
import WorkersContextProvider from './context/WorkersContext';
import EmployerCalendarContextProvider from './context/EmployerCalendarContext';
import UserCalendarContextProvider from './context/UserCalendarContext';
import NotificationsContextProvider from './context/NotificationsContext';
import { AmplifyAuthProvider } from './context/AmplifyAuthContext';
import { Compose } from './helpers/functions';
import SkillsContextProvider from './context/SkillsContext';

const components = [
  ProfileContextProvider,
  EmployerContextProvider,
  WorkerContextProvider,
  UserDetailsFormContextProvider,
  EmployerDetailsFormContextProvider,
  OffersContextProvider,
  UserAccountContextProvider,
  EmployerAccountContextProvider,
  FavoriteContextProvider,
  CompaniesContextProvider,
  WorkersContextProvider,
  AmplifyAuthProvider,
  AuthContextProvider,
  EmployerCalendarContextProvider,
  UserCalendarContextProvider,
  NotificationsContextProvider,
  SkillsContextProvider,
];

const App: React.FC = () => {
  const locale = localStorage.getItem('i18nextLng') || 'pl';
  return (
    <ThemeProvider theme={theme}>
      <Compose components={components}>
        <ConfigProvider locale={locale === 'pl' ? plPL : enUS}>
          <PathRouter />
        </ConfigProvider>
      </Compose>
    </ThemeProvider>
  );
};

export default App;
