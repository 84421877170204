import { createContext, useState } from 'react';
import EmployerDetailsFormApi from '../api/detailsForm/EmployerDetailsForm';
import { PhonePrefix } from './UserDetailsFormContext';

interface PersonalData {
  name: string;
  surname: string;
  position: string;
  email: string;
  phone: string;
  phonePrefix: PhonePrefix;
  statement: string | boolean;
}

interface TaxData {
  name: string;
  nip: string;
  street: string;
  buildingNumber: string;
  city: string;
  zipCode: string;
  country: string;
  voivodeship: string;
}

interface BaseCompanyData {
  name: string;
  street: string;
  buildingNumber: string;
  city: string;
  zipCode: string;
  country: string;
  voivodeship: string;
  website: string;
}

export type EmployerDetailsFormContextState = {
  personalData: PersonalData;
  taxData: TaxData;
  baseCompanyData: BaseCompanyData;
  logoUrl: string;
  setPersonalData: (personalData: PersonalData) => void;
  setTaxData: (taxData: TaxData) => void;
  setBaseCompanyData: (baseCompanyData: BaseCompanyData) => void;
  setLogoUrl: (logoUrl: string) => void;
  clearContext: () => void;
  saveLogo: (logo: string) => any;
  checkPersonalDataStep: (data: PersonalData) => any;
  checkInstitutionDataStep: (data: BaseCompanyData) => any;
  checkTaxDataStep: (data: TaxData) => any;
};

const contextDefaultValues: EmployerDetailsFormContextState = {
  personalData: {
    name: '',
    surname: '',
    position: '',
    email: '',
    phone: '',
    phonePrefix: PhonePrefix.POLAND_PHONE_PREFIX,
    statement: '',
  },
  taxData: {
    name: '',
    nip: '',
    street: '',
    buildingNumber: '',
    city: '',
    zipCode: '',
    country: '',
    voivodeship: '',
  },
  baseCompanyData: {
    name: '',
    street: '',
    buildingNumber: '',
    city: '',
    zipCode: '',
    country: '',
    voivodeship: '',
    website: '',
  },
  logoUrl: '',
  setPersonalData: () => {},
  setTaxData: () => {},
  setBaseCompanyData: () => {},
  setLogoUrl: () => {},
  clearContext: () => {},
  saveLogo: () => {},
  checkPersonalDataStep: () => {},
  checkInstitutionDataStep: () => {},
  checkTaxDataStep: () => {},
};

export const EmployerDetailsFormContext =
  createContext<EmployerDetailsFormContextState>(contextDefaultValues);

const EmployerDetailsFormContextProvider: React.FC = ({ children }) => {
  const [personalData, setPersonalData] = useState<PersonalData>(
    contextDefaultValues.personalData
  );
  const [taxData, setTaxData] = useState<TaxData>(contextDefaultValues.taxData);
  const [baseCompanyData, setBaseCompanyData] = useState<BaseCompanyData>(
    contextDefaultValues.baseCompanyData
  );
  const [logoUrl, setLogoUrl] = useState<string>(contextDefaultValues.logoUrl);

  const clearContext = () => {
    setPersonalData(contextDefaultValues.personalData);
    setTaxData(contextDefaultValues.taxData);
    setBaseCompanyData(contextDefaultValues.baseCompanyData);
    setLogoUrl(contextDefaultValues.logoUrl);
  };

  const employerDetailsFormApi = new EmployerDetailsFormApi();

  const saveLogo = (logo: string) => employerDetailsFormApi.saveLogoData({ logo });

  const checkPersonalDataStep = (data: PersonalData) =>
    employerDetailsFormApi.checkPersonalData({
      first_name: data.name,
      last_name: data.surname,
      position: data.position,
      email: data.email,
      phone: data.phonePrefix + data.phone,
    });
  const checkInstitutionDataStep = (data: BaseCompanyData) =>
    employerDetailsFormApi.checkInstitutionData({
      name: data.name,
      street: data.street,
      building_number: data.buildingNumber,
      city: data.city,
      zip_code: data.zipCode,
      country: data.country,
      voivodeship: data.voivodeship,
      website: data.website,
    });
  const checkTaxDataStep = (data: TaxData) =>
    employerDetailsFormApi.checkTaxData({
      company_name: data.name,
      NIP: data.nip,
      street: data.street,
      building_number: data.buildingNumber,
      city: data.city,
      zip_code: data.zipCode,
      country: data.country,
      voivodeship: data.voivodeship,
    });

  return (
    <EmployerDetailsFormContext.Provider
      value={{
        personalData,
        taxData,
        baseCompanyData,
        logoUrl,
        setPersonalData,
        setTaxData,
        setBaseCompanyData,
        setLogoUrl,
        clearContext,
        saveLogo,
        checkPersonalDataStep,
        checkInstitutionDataStep,
        checkTaxDataStep,
      }}>
      {children}
    </EmployerDetailsFormContext.Provider>
  );
};

export default EmployerDetailsFormContextProvider;
