import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ScrollToTopPage from './utils/ScrollToPageTop';
import paths from './paths.json';
import ProtectedRoute from './utils/ProtectedRoute';
import UnProtectedRoute from './utils/UnProtectedRoute';
import PanelLayout from './components/PanelLayout';
import Spin from './components/Spin';

const LoginPage = lazy(() => import('./pages/login/LoginPage'));
const ActivateSuccess = lazy(() => import('./pages/login/ActivateSuccess'));
const ActivateFailed = lazy(() => import('./pages/login/ActivateFailed'));
const MyWorkinn = lazy(() => import('./pages/myWorkinn/MyWorkinn'));
const MainPage = lazy(() => import('./pages/MainPage'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const JobOffers = lazy(() => import('./pages/jobOffers/JobOffers'));
const Calendar = lazy(() => import('./pages/calendar/Calendar'));
const RegisterPage = lazy(() => import('./pages/login/RegisterPage'));
const RemindPasswordPage = lazy(() => import('./pages/login/RemindPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/login/ResetPasswordPage'));
const SendActivationLinkPage = lazy(() => import('./pages/login/SendActivationLinkPage'));
const RegisterSuccessPage = lazy(() => import('./pages/login/RegisterSuccessPage'));
const ProfilePage = lazy(() => import('./pages/profile/ProfilePage'));
const UserDetailsForm = lazy(() => import('./pages/userDetailsForm/UserDetailsForm'));
const EmployerDetailsForm = lazy(
  () => import('./pages/employerDetailsForm/EmployerDetailsForm')
);
const AddOffer = lazy(() => import('./pages/addOffer/AddOffer'));
const UserAccount = lazy(() => import('./pages/userAccount/UserAccount'));
const EmployerAccount = lazy(() => import('./pages/employerAccount/EmployerAccount'));
const JobOffer = lazy(() => import('./pages/jobOffers/JobOffer'));
const EmployerProfile = lazy(() => import('./pages/employerProfile/EmployerProfile'));
const WorkerProfile = lazy(() => import('./pages/workerProfile/WorkerProfile'));
const MobileNotifications = lazy(() => import('./pages/mobileNotifications'));
const Inbox = lazy(() => import('./pages/Inbox'));

const fallback = (
  <div style={{ width: '100%', height: '100vh' }}>
    <Spin />
  </div>
);

const PathRouter: React.FC = () => (
  <Router>
    <Suspense fallback={fallback}>
      <ScrollToTopPage />
      <Switch>
        <UnProtectedRoute path={paths.mainPage} exact component={MainPage} />
        <UnProtectedRoute path={paths.loginPage} exact component={LoginPage} />
        <UnProtectedRoute path={paths.registerPage} exact component={RegisterPage} />
        <UnProtectedRoute
          path={paths.remindPasswordPage}
          exact
          component={RemindPasswordPage}
        />
        <UnProtectedRoute
          path={paths.resetPassword}
          exact
          component={ResetPasswordPage}
        />
        <UnProtectedRoute
          path={paths.sendActivationLinkPage}
          exact
          component={SendActivationLinkPage}
        />
        <UnProtectedRoute
          path={paths.registerSuccessPage}
          exact
          component={RegisterSuccessPage}
        />
        <UnProtectedRoute
          path={paths.activateSuccess}
          exact
          component={ActivateSuccess}
        />
        <UnProtectedRoute path={paths.activateFailed} exact component={ActivateFailed} />
        <ProtectedRoute path={paths.userDetails} component={UserDetailsForm} />
        <ProtectedRoute path={paths.employerDetails} component={EmployerDetailsForm} />

        <PanelLayout>
          <Suspense fallback={<Spin />}>
            <ProtectedRoute path={paths.dashboard} exact component={Dashboard} />
            <ProtectedRoute path={paths.myWorkinn} exact component={MyWorkinn} />
            <ProtectedRoute path={paths.jobOffers} exact component={JobOffers} />
            <ProtectedRoute path={paths.jobOffer} exact component={JobOffer} />
            <ProtectedRoute path={paths.calendar} exact component={Calendar} />
            <ProtectedRoute path={paths.profile} exact component={ProfilePage} />
            <ProtectedRoute path={paths.addOffer} exact component={AddOffer} />
            <ProtectedRoute path={paths.userAccount} component={UserAccount} />
            <ProtectedRoute path={paths.employerAccount} component={EmployerAccount} />
            <ProtectedRoute path={paths.employer} exact component={EmployerProfile} />
            <ProtectedRoute path={paths.worker} exact component={WorkerProfile} />
            <ProtectedRoute path={paths.channelsList} component={Inbox} />
            <ProtectedRoute
              path={paths.mobileNotifications}
              exact
              component={MobileNotifications}
            />
          </Suspense>
        </PanelLayout>
      </Switch>
    </Suspense>
  </Router>
);

export default PathRouter;
