import { createUrl } from '../helpers/functions';
import HttpClient from '../utils/http-client';
import { environmentConfig } from '../Config';

const PATHS = {
  companies: '/v1/employers/',
};

export type TCompany = {
  id: number;
  logo?: string;
  company_name: string;
  institution?: string;
  city: string;
  job_offer_count: string;
};

export type TCompaniesResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<TCompany>;
};

export type TCompaniesQueryParams = {
  page?: number;
  page_size?: number;
  distance?: string;
  city_lng?: number;
  city_lat?: number;
  city?: string;
};

class CompaniesApi extends HttpClient {
  constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public get = async (queryParams?: TCompaniesQueryParams): Promise<TCompaniesResponse> =>
    this.instance.get<any, TCompaniesResponse>(createUrl(PATHS.companies, queryParams));
}

export default CompaniesApi;
