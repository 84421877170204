import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, Modal, Upload } from 'antd';
import * as yup from 'yup';
import Avatar from 'react-avatar-edit';
import Text from './Text';
import LogoPlaceholder from '../assets/logoPlaceholder.svg';
import useWindowDimensions from '../utils/WindowDimension';
import customNotification from './notification';
import theme from '../utils/theme';
import { getBase64 } from '../helpers/functions';
import { ProfileContext } from '../context/ProfileContext';
import { EmployerAccountContext } from '../context/EmployerAccountContext';
import PrimaryButton from './buttons/PrimaryButton';
import { UserAccountContext } from '../context/UserAccountContext';
import { TData } from '../context/EmployerContext';
import { TData as TDataWorker } from '../context/WorkerContext';

type ChangeAvatarModalProps = {
  userData: TData | TDataWorker;
  employer: boolean;
};

const ChangeAvatarModal = ({ userData, employer }: ChangeAvatarModalProps) => {
  const { t } = useTranslation();
  const {
    basicData,
    saveBasicData,
    setBasicData,
    loadData: loadDataEmployer,
    clearContext: clearContextEmployer,
  } = useContext(EmployerAccountContext);

  const {
    personalData,
    savePersonalData,
    setPersonalData,
    loadData: loadDataUser,
    clearContext: clearContextUser,
  } = useContext(UserAccountContext);

  const { profileId } = useContext(ProfileContext);

  const { width } = useWindowDimensions();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string>(basicData.logo);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onClose = () => {
    setAvatar('');
  };

  useEffect(() => {
    if (profileId) {
      if (employer) {
        loadDataEmployer(`${profileId}`);
      } else {
        loadDataUser(`${profileId}`);
      }
    }

    return () => {
      clearContextEmployer();
      clearContextUser();
    };
  }, [profileId, userData]);

  const EmployerChangeLogoForm = (
    <>
      <Formik
        validateOnChange={false}
        initialValues={basicData}
        onSubmit={async values => {
          setIsSubmitting(true);
          try {
            setBasicData({ ...basicData, logo: values.logo });
            await saveBasicData(profileId || '', { ...basicData, logo: values.logo });
            setIsSubmitting(false);
          } catch (error) {
            console.error('Error:', error);
            setIsSubmitting(false);
          }
        }}>
        {({ errors, handleSubmit, setFieldValue, values }) => (
          <Row>
            <Col>
              <ImageWrapper>
                <AvatarCircle
                  src={values.logo ? values.logo || LogoPlaceholder : userData.logo}
                />
              </ImageWrapper>
            </Col>
            <Col>
              <Upload
                accept=".jpg,.png,.jpeg"
                beforeUpload={file => {
                  setAvatar('');
                  setFieldValue('avatar', null);
                  if (file.size > 3000000) {
                    customNotification({
                      type: 'error',
                      description: t('userDetailsForm.photoStep.maxSizeExceeded'),
                    });
                  } else {
                    getBase64(file, (imageUrl: any) => {
                      setAvatar(imageUrl);
                      setFieldValue('logo', imageUrl);
                      setModalVisible(true);
                      customNotification({
                        type: 'info',
                        description: t('userDetailsForm.photoStep.addPhotoNotification'),
                      });
                    });
                  }
                  return false;
                }}
                onChange={() => null}
                listType="picture"
                maxCount={1}
                showUploadList={false}>
                <UploadWrapper>
                  <Text
                    message={t('employerDetailsForm.logoStep.title')}
                    type={width < theme.screenWidth.xs ? 'big' : 'large'}
                    weight={700}
                  />
                  <StyledText
                    message={t(
                      basicData.logo
                        ? 'employerDetailsForm.logoStep.changeLogo'
                        : 'employerDetailsForm.logoStep.upload'
                    )}
                    type="normal"
                    color="primary"
                  />
                  {errors.logo && <Text message={errors.logo} type="small" color="red" />}
                </UploadWrapper>
              </Upload>
              <PrimaryButtonStyled
                label={t('global.save')}
                onClick={() => handleSubmit()}
                disabled={isSubmitting || !values.logo}
              />
            </Col>
            <ModalScale
              title="Vertically centered modal dialog"
              centered
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}>
              <Avatar
                width={300}
                height={400}
                onCrop={view => {
                  setFieldValue('logo', view);
                }}
                onClose={() => {
                  onClose();
                  setAvatar('');
                }}
                src={avatar}
              />
              <Button type="primary" onClick={() => setModalVisible(false)}>
                {t('userAccount.basicStep.ready')}
              </Button>
            </ModalScale>
          </Row>
        )}
      </Formik>
    </>
  );

  const UserChangeAvatarForm = (
    <>
      <Formik
        validateOnChange={false}
        initialValues={personalData}
        onSubmit={async values => {
          try {
            setIsSubmitting(true);
            setPersonalData({ ...personalData, avatar: values.avatar });
            await savePersonalData(profileId || '', {
              ...personalData,
              avatar: values.avatar,
            });
            setIsSubmitting(false);
          } catch (error) {
            console.error('Error:', error);
            setIsSubmitting(false);
          }
        }}>
        {({ errors, handleSubmit, setFieldValue, values }) => (
          <Row>
            <Col>
              <ImageWrapper>
                <AvatarCircle
                  src={values.avatar ? values.avatar || LogoPlaceholder : userData.avatar}
                />
              </ImageWrapper>
            </Col>
            <Col>
              <Upload
                accept=".jpg,.png,.jpeg"
                beforeUpload={file => {
                  setAvatar('');
                  setFieldValue('avatar', null);
                  if (file.size > 3000000) {
                    customNotification({
                      type: 'error',
                      description: t('userDetailsForm.photoStep.maxSizeExceeded'),
                    });
                  } else {
                    getBase64(file, (imageUrl: any) => {
                      setAvatar(imageUrl);
                      setFieldValue('avatar', imageUrl);
                      setModalVisible(true);
                      customNotification({
                        type: 'info',
                        description: t('userDetailsForm.photoStep.addPhotoNotification'),
                      });
                    });
                  }
                  return false;
                }}
                onChange={() => null}
                listType="picture"
                maxCount={1}
                showUploadList={false}>
                <UploadWrapper>
                  <Text
                    message={t(`${personalData.name}`)}
                    type={width < theme.screenWidth.xs ? 'big' : 'large'}
                    weight={700}
                  />
                  <StyledText
                    message={t(
                      personalData.avatar
                        ? 'userAccount.basicStep.upload'
                        : 'userDetailsForm.photoStep.upload'
                    )}
                    type="normal"
                    color="primary"
                  />
                  {errors.avatar && (
                    <Text message={errors.avatar} type="small" color="red" />
                  )}
                </UploadWrapper>
              </Upload>
              <PrimaryButtonStyled
                label={t('global.save')}
                onClick={() => handleSubmit()}
                disabled={isSubmitting || !values.avatar}
              />
            </Col>
            <ModalScale
              title="Vertically centered modal dialog"
              centered
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}>
              <Avatar
                width={300}
                height={400}
                onCrop={view => {
                  setFieldValue('avatar', view);
                }}
                onClose={() => {
                  onClose();
                  setFieldValue('avatar', '');
                  setAvatar('');
                }}
                src={avatar}
              />
              <Button type="primary" onClick={() => setModalVisible(false)}>
                {t('userAccount.basicStep.ready')}
              </Button>
            </ModalScale>
          </Row>
        )}
      </Formik>
    </>
  );

  return employer ? EmployerChangeLogoForm : UserChangeAvatarForm;
};

export default ChangeAvatarModal;

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 94px;
  margin-left: 1.6875rem;
`;

const StyledText = styled(Text)`
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const ImageWrapper = styled.div<{ src?: string }>`
  width: 5.875rem;
  height: 5.875rem;
  border-radius: 4rem;
  overflow: hidden;
  background: ${props => props.theme.color.secondary};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarCircle = styled.img`
  width: 5.875rem;
  height: 5.875rem;
  border-radius: 4rem;
  object-fit: cover;
`;

const ModalScale = styled(Modal)`
  .ant-modal-close-x,
  .ant-modal-footer,
  .ant-modal-header {
    display: none;
  };

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-item-center;
    padding: 0;

    .ant-btn-primary {
      position: absolute;
      bottom: -60px;
      font-size: 18px;
      padding: 10px;
      width: 200p;
      height: auto;
      font-weight: 600;
    };

    svg {
      display: none;
    }
  }
`;

const PrimaryButtonStyled = styled(PrimaryButton)`
  right: 15px;
  bottom: 10px;
  position: absolute;
`;
