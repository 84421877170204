import { TNotification } from '../api/NotificationsApi';

export function getNotificationCommonData(notification: TNotification) {
  const workerName = notification.meta?.worker_profile
    ? `${notification.meta?.worker_profile.first_name} ${notification.meta?.worker_profile.last_name}`
    : '';

  const userName = notification.meta?.chat?.user
    ? `${notification.meta?.chat?.user.first_name} ${notification.meta?.chat?.user.last_name}`
    : '';

  const employerName = notification.meta?.employer_profile
    ? notification.meta?.employer_profile.company_name
    : '';

  const messageCompanyName =
    notification.meta?.chat?.user && notification.meta?.chat?.user.company_name
      ? ` (${notification.meta?.chat?.user.company_name})`
      : '';

  return {
    workerName,
    userName,
    employerName,
    messageCompanyName,
  };
}
