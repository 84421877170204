import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { ReactElement } from 'react';

type ButtonType = 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default';

type ButtonProps = {
  label: ReactElement | string | string[];
  width?: string;
  fontWeight?: number;
  onClick?: () => void;
  type?: ButtonType;
  marginBottom?: string;
  marginTop?: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  inline?: boolean;
  underline?: boolean;
};

const PrimaryButton: React.FC<ButtonProps> = ({
  width = 'auto',
  label,
  fontWeight = 700,
  onClick,
  type,
  marginBottom,
  marginTop,
  className,
  loading = false,
  disabled,
  inline = false,
  underline = false,
}) => (
  <StyledButton
    className={className}
    width={width}
    fontWeight={fontWeight}
    onClick={onClick}
    htmlType={type}
    loading={loading}
    marginTop={marginTop}
    marginBottom={marginBottom}
    disabled={disabled}
    inline={inline}
    underline={underline}>
    {label}
  </StyledButton>
);

const typeToColor = (type: ButtonType, color: any) => {
  switch (type) {
    case 'ghost':
      return color.secondary;
    default:
      return color.white;
  }
};

const typeToBackground = (type: ButtonType, color: any) => {
  switch (type) {
    case 'ghost':
      return color.none;
    default:
      return color.primary;
  }
};

const typeToBorderColor = (type: ButtonType, color: any) => {
  switch (type) {
    case 'ghost':
      return color.none;
    default:
      return color.primary;
  }
};

const StyledButton = styled(Button)<any>(
  ({
    width,
    fontWeight,
    marginTop,
    marginBottom,
    theme: { color, fontSize },
    htmlType,
    inline,
    underline,
  }) => css`
    height: 2.5rem;
    width: ${width};
    color: ${typeToColor(htmlType, color)};
    border-radius: 1.25rem;
    background: ${typeToBackground(htmlType, color)};
    font-weight: ${fontWeight};
    font-size: ${fontSize.medium};
    border-color: ${typeToBorderColor(htmlType, color)};
    margin-top: ${marginTop};
    margin-bottom: ${marginBottom};
    box-shadow: none;
    display: ${inline ? 'inline' : 'block'};
    padding: ${inline ? '0.2rem' : 'auto'};
    :hover {
      color: ${typeToColor(htmlType, color)};
      background: ${typeToBackground(htmlType, color)};
      border-color: ${typeToBorderColor(htmlType, color)};
      opacity: 0.8;
    }
    :focus {
      color: ${typeToColor(htmlType, color)};
      background: ${typeToBackground(htmlType, color)};
      border-color: ${typeToBorderColor(htmlType, color)};
    }
    span {
      text-decoration: ${underline ? 'underline' : 'none'};
    }
  `
);

export default PrimaryButton;
