import { createContext, useState } from 'react';
import CompaniesApi, { TCompany, TCompaniesQueryParams } from '../api/Companies';

type CompaniesContextState = {
  loading: boolean;
  errorMessage: string;
  updateData: (queryParams?: TCompaniesQueryParams) => void;
  page: number;
  params: TCompaniesQueryParams;
  changePage: (newPage: number) => void;
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Array<TCompany>;
  };
  clearContext: () => void;
};

const companiesContextInitialState: CompaniesContextState = {
  loading: false,
  errorMessage: '',
  updateData: () => {},
  changePage: () => {},
  page: 1,
  params: {},
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  clearContext: () => {},
};

const api = new CompaniesApi();

export const CompaniesContext = createContext<CompaniesContextState>(
  companiesContextInitialState
);

const CompaniesContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<CompaniesContextState['loading']>(
    companiesContextInitialState.loading
  );
  const [errorMessage, setErrorMessage] = useState<CompaniesContextState['errorMessage']>(
    companiesContextInitialState.errorMessage
  );
  const [page, changePage] = useState<CompaniesContextState['page']>(
    companiesContextInitialState.page
  );
  const [data, setData] = useState<CompaniesContextState['data']>(
    companiesContextInitialState.data
  );
  const [params, setParams] = useState<CompaniesContextState['params']>(
    companiesContextInitialState.params
  );

  const updateData = async (queryParams?: TCompaniesQueryParams) => {
    setLoading(true);
    const offersData = await api.get({
      ...queryParams,
      page_size: queryParams?.page_size || 9,
    });
    if (queryParams?.page) changePage(queryParams.page);
    setData(offersData);
    setParams(queryParams || {});
    setLoading(false);
  };

  const clearContext = () => {
    setData(companiesContextInitialState.data);
    setParams(companiesContextInitialState.params);
  };

  return (
    <CompaniesContext.Provider
      value={{
        loading,
        errorMessage,
        updateData,
        data,
        page,
        changePage,
        params,
        clearContext,
      }}>
      {children}
    </CompaniesContext.Provider>
  );
};

export default CompaniesContextProvider;
