import HttpClient from '../../utils/http-client';
import { createUrl } from '../../helpers/functions';
import {
  TSaveUserBasicData,
  TSaveUserExperienceData,
  TSaveUserQuestionaireData,
  TSaveZusData,
  TUserData,
  TSaveTaxData,
  TEarningsStats,
  TOrdersStats,
  TCompaniesStats,
} from './types';
import { environmentConfig } from '../../Config';

const PATHS = {
  save: (id: string | number) => `/v1/workers/${id}/details/`,
  load: (id: string | number) => `/v1/workers/${id}/details/`,
  getEarningsStats: '/v1/statistic/earnings/',
  getOrdersStats: '/v1/statistic/orders/',
  getCompaniesStats: '/v1/statistic/companies/',
};

type ID = string | number;

class UserAccountApi extends HttpClient {
  public constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public save = async (
    id: string | number,
    credentials:
      | TSaveUserBasicData
      | TSaveUserExperienceData
      | TSaveUserQuestionaireData
      | TSaveZusData
      | TSaveTaxData
  ): Promise<any> =>
    this.instance.patch<
      | TSaveUserBasicData
      | TSaveUserExperienceData
      | TSaveUserQuestionaireData
      | TSaveZusData
      | TSaveTaxData
    >(PATHS.save(id), credentials);

  public load = async (id: ID): Promise<TUserData> =>
    this.instance.get<never, TUserData>(PATHS.load(id));

  public getEarningsStats = async (queryParrams: {
    start_date: string;
    end_date: string;
  }): Promise<TEarningsStats> =>
    this.instance.get<never, TEarningsStats>(
      createUrl(PATHS.getEarningsStats, queryParrams)
    );

  public getOrdersStats = async (queryParrams: {
    start_date: string;
    end_date: string;
    settled?: boolean;
  }): Promise<TOrdersStats> =>
    this.instance.get<never, TOrdersStats>(createUrl(PATHS.getOrdersStats, queryParrams));

  public getCompaniesStats = async (queryParrams: {
    start_date: string;
    end_date: string;
  }): Promise<TCompaniesStats> =>
    this.instance.get<never, TCompaniesStats>(
      createUrl(PATHS.getCompaniesStats, queryParrams)
    );
}

export default UserAccountApi;
