import { ROLES } from '../helpers/constants';
import HttpClient from '../utils/http-client';
import { TOffersStats, TWorkersStats, TEarningsStats } from './account/types';
import { environmentConfig } from '../Config';

const PATHS = {
  me: '/v1/me/',
  worker: (id: number | string) => `/v1/workers/${id}/`,
  employer: (id: number | string) => `/v1/employers/${id}/`,
  favorites: '/v1/favorites/',
  deleteFavorite: (id: number) => `/v1/favorites/${id}/`,
  changeEmail: '/v1/users/set_email/',
  changePass: '/v1/users/set_password/',
  deactivate: '/v1/users/deactivate/',
  getAllOffersStats: '/v1/statistic/offers/',
  getAllWorkersStats: '/v1/statistic/workers/',
  getAllEarningsStats: '/v1/statistic/earnings/',
};

type Translation = {
  language_tag: string;
  translation: string;
};

type TranslationSkills = {
  id: number;
  translations: Translation[];
};

type SpecialitieType = {
  id: number;
  speciality: string;
  translations: Translation[];
};

type TRole = ROLES;

type TFavoriteProfile = {
  city: string;
  id: number;
  avatar?: string;
  logo?: string;
  rating: {
    organization: number;
    quality: number;
    atmosphere: number;
    communication: number;
  } | null;
  rating_count: number;
  company_name?: string;
  first_name?: string;
};

type TMeResponse = {
  profile_id: number;
  client_type: TRole;
  profile_completed: boolean;
  verified?: boolean;
};

type TWorkerResponse = {
  avatar: string;
  first_name: string;
  last_name: string;
  sex: 'MALE' | 'FEMALE' | string;
  city: string;
  rating: {
    organization: number;
    quality: number;
    atmosphere: number;
    communication: number;
  } | null;
  rating_count: number;
  profile_completed: boolean;
  careers: number[];
  abilities: TranslationSkills[];
  description: string;
  hours_worked: number;
  orders_count: number;
  applications?: Array<{
    speciality: string;
    event_dates: Array<Array<string>>;
    id: number;
    location__city: string;
  }>;
  employment_declaration?: Array<{
    key: string;
    value: true;
  }>;
  dimensions?: {
    weight: number;
    height: number;
    hips: number;
    waist: number;
    chest: number;
  };
  experience: Array<{
    id: number;
    rating: {
      organization: number;
      quality: number;
      atmosphere: number;
      communication: number;
    } | null;
    rating_count: number;
    is_workinn: boolean;
    company: string;
    position: string;
    description: string;
    created_at: string;
  }>;
};

type TEmployerResponse = {
  city: string;
  logo: string;
  company_name: string;
  website: string;
  rating: {
    organization: number;
    quality: number;
    atmosphere: number;
    communication: number;
  } | null;
  rating_count: number;
  description: string;
  job_offer_count: string;
  institution: string;
  worker_count: number;
  verified?: boolean;
  job_offers: Array<{
    id: number;
    event_dates: Array<Array<string>>;
    location: {
      city: string;
      address: string;
    };
    hourly_rate: string;
    is_active: boolean;
    speciality: SpecialitieType;
    title: string;
    opinions: Array<{
      rating_count: number;
      rating: {
        atmosphere: string;
        average: string;
        communication: string;
        id: number;
        organization: string;
        quality: string;
      };
    }> | null;
  }>;
};

class ProfileApi extends HttpClient {
  constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public getInfo = async (): Promise<TMeResponse | undefined> =>
    this.instance.get<never, TMeResponse>(PATHS.me);

  public getWorker = async (id: number | string): Promise<TWorkerResponse> =>
    this.instance.get<never, TWorkerResponse>(PATHS.worker(id));

  public getEmployer = async (id: number): Promise<TEmployerResponse> =>
    this.instance.get<never, TEmployerResponse>(PATHS.employer(id));

  public addFavorite = async (
    id: number
  ): Promise<{ id: number; profile: TFavoriteProfile }> =>
    this.instance.post<{ profile_id: number }, { id: number; profile: TFavoriteProfile }>(
      PATHS.favorites,
      {
        profile_id: id,
      }
    );

  public getFavorites = async (): Promise<
    Array<{ id: number; profile: TFavoriteProfile }>
  > =>
    this.instance.get<never, Array<{ id: number; profile: TFavoriteProfile }>>(
      PATHS.favorites
    );

  public removeFavorite = async (id: number) =>
    this.instance.delete<never, any>(PATHS.deleteFavorite(id));

  public changeEmail = async (data: {
    email: string;

    // confirmEmail: string
  }) => this.instance.post(PATHS.changeEmail, data);

  public changePass = async (data: {
    current_password: string;
    new_password: string;
    confirm_password: string;
  }) => this.instance.post(PATHS.changePass, data);

  public deactivate = async (data: { password: string; repeat_password: string }) =>
    this.instance.post(PATHS.deactivate, data);

  public getAllOffersStats = async (): Promise<TOffersStats> =>
    this.instance.get<never, TOffersStats>(PATHS.getAllOffersStats);

  public getAllWorkersStats = async (): Promise<TWorkersStats> =>
    this.instance.get<never, TWorkersStats>(PATHS.getAllWorkersStats);

  public getAllEarningsStats = async (): Promise<TEarningsStats> =>
    this.instance.get<never, TEarningsStats>(PATHS.getAllEarningsStats);
}

export default ProfileApi;
