import { createContext, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import EmployerAccountApi from '../api/account/EmployerAccount';
import customNotification from '../components/notification';
import { getErrorFromResponse } from '../helpers/functions';

interface BasicData {
  name: string;
  surname: string;
  position: string;
  email: string;
  phone: string;
  description: string;
  logo: string;
  phone_country_code?: string;
}
interface Institution {
  name: string;
  street: string;
  buildingNumber: string;
  city: string;
  zipCode: string;
  country: string;
  voivodeship: string;
  website: string;
}

interface Tax {
  companyName: string;
  nip?: string;
  street: string;
  buildingNumber: string;
  city: string;
  zipCode: string;
  country: string;
  voivodeship: string;
}

export type EmployerAccountContextState = {
  basicData: BasicData;
  institution: Institution;
  tax: Tax;
  loading: boolean;
  loadData: (id: string) => void;
  setBasicData: (basicData: BasicData) => void;
  setTaxData: (tax: Tax) => void;
  setInstitutionData: (institution: Institution) => void;
  clearContext: () => void;
  saveBasicData: (id: string | number, personalData: BasicData) => void;
  saveTaxData: (id: string | number, tax: Tax) => void;
  saveInstitutionData: (id: string | number, institution: Institution) => void;
};

const contextDefaultValues: EmployerAccountContextState = {
  basicData: {
    name: '',
    surname: '',
    position: '',
    email: '',
    phone: '',
    description: '',
    logo: '',
  },
  institution: {
    name: '',
    street: '',
    buildingNumber: '',
    city: '',
    zipCode: '',
    country: '',
    voivodeship: '',
    website: '',
  },
  tax: {
    companyName: '',
    nip: '',
    street: '',
    buildingNumber: '',
    city: '',
    zipCode: '',
    country: '',
    voivodeship: '',
  },
  loading: false,
  loadData: () => {},
  setBasicData: () => {},
  saveBasicData: () => {},
  setTaxData: () => {},
  saveTaxData: () => {},
  setInstitutionData: () => {},
  saveInstitutionData: () => {},
  clearContext: () => {},
};

export const EmployerAccountContext =
  createContext<EmployerAccountContextState>(contextDefaultValues);

const EmployerAccountContextProvider: React.FC = ({ children }) => {
  const [basicData, setBasicData] = useState<BasicData>(contextDefaultValues.basicData);
  const [institution, setInstitutionData] = useState<Institution>(
    contextDefaultValues.institution
  );
  const [tax, setTaxData] = useState<Tax>(contextDefaultValues.tax);
  const [loading, setLoading] = useState<boolean>(contextDefaultValues.loading);
  const [logoHelper, setLogoHelper] = useState<string>('');

  const { t } = useTranslation();

  const clearContext = () => {
    setBasicData(contextDefaultValues.basicData);
  };

  const employerAccountApi = new EmployerAccountApi();

  const saveBasicData = (id: string | number, data: BasicData) => {
    const credentials = {
      description: data.description ? data.description : '',
      logo: logoHelper === data.logo ? undefined : data.logo,
      first_name: data.name,
      last_name: data.surname,
      position: data.position,
      email: data.email,
      phone: data.phone_country_code + data.phone,
    };
    employerAccountApi
      .save(id, credentials)
      .then(() =>
        customNotification({
          type: 'success',
          description: t('global.saveSuccess'),
        })
      )
      .catch((err: any) => {
        const details: Record<string, any> = err?.response?.data;
        const error = getErrorFromResponse(details?.errors[0]);
        customNotification({
          type: 'error',
          description: t(
            `employerDetailsForm.errors.${error.field !== null ? error.field + '_' : ''}${
              error.code
            }`
          ),
        });
      });
  };
  const saveTaxData = (id: string | number, data: Tax) => {
    const credentials = {
      company_name: data.companyName,
      street: data.street,
      building_number: data.buildingNumber,
      city: data.city,
      zip_code: data.zipCode,
      country: data.country,
      voivodeship: data.voivodeship,
    };
    employerAccountApi
      .save(id, { tax: credentials })
      .then(() =>
        customNotification({
          type: 'success',
          description: t('global.saveSuccess'),
        })
      )
      .catch((err: any) => {
        const details: Record<string, any> = err?.response?.data;
        const error = getErrorFromResponse(details?.errors[0]);
        customNotification({
          type: 'error',
          description: t(
            `employerDetailsForm.errors.${error.field !== null ? error.field + '_' : ''}${
              error.code
            }`
          ),
        });
      });
  };
  const saveInstitutionData = (id: string | number, data: Institution) => {
    const credentials = {
      name: data.name,
      street: data.street,
      building_number: data.buildingNumber,
      city: data.city,
      zip_code: data.zipCode,
      country: data.country,
      voivodeship: data.voivodeship,
      website: data.website,
    };
    employerAccountApi
      .save(id, { institution: credentials })
      .then(() =>
        customNotification({
          type: 'success',
          description: t('global.saveSuccess'),
        })
      )
      .catch((err: any) => {
        const details: Record<string, any> = err?.response?.data;
        const error = getErrorFromResponse(details?.errors[0]);
        customNotification({
          type: 'error',
          description: t(
            `employerDetailsForm.errors.${error.field !== null ? error.field + '_' : ''}${
              error.code
            }`
          ),
        });
      });
  };

  const loadData = async (id: string | number) => {
    setLoading(true);
    try {
      const employerData = await employerAccountApi.load(id);
      setBasicData(state => ({
        name: employerData.first_name,
        surname: employerData.last_name,
        position: employerData.position,
        email: employerData.email,
        phone: employerData.phone,
        description: employerData.description,
        logo: employerData.logo,
        phone_country_code: employerData.phone_country_code,
      }));
      setLogoHelper(state => employerData.logo);
      setInstitutionData(state => ({
        name: employerData.institution?.name,
        street: employerData.institution?.street,
        buildingNumber: employerData.institution?.building_number,
        city: employerData.institution?.city,
        zipCode: employerData.institution?.zip_code,
        country: employerData.institution?.country,
        voivodeship: employerData.institution?.voivodeship,
        website: employerData?.institution?.website,
      }));
      setTaxData(state => ({
        companyName: employerData.tax?.company_name,
        nip: employerData.tax?.NIP,
        street: employerData.tax?.street,
        buildingNumber: employerData.tax?.building_number,
        city: employerData.tax?.city,
        zipCode: employerData.tax?.zip_code,
        country: employerData.tax?.country,
        voivodeship: employerData.tax?.voivodeship,
      }));
    } catch (err: any) {
      if (err?.response) {
        customNotification({ type: 'error', description: t('global.loadDataError') });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmployerAccountContext.Provider
      value={{
        basicData,
        institution,
        tax,
        loading,
        loadData,
        clearContext,
        setBasicData,
        saveBasicData,
        setTaxData,
        saveTaxData,
        setInstitutionData,
        saveInstitutionData,
      }}>
      {children}
    </EmployerAccountContext.Provider>
  );
};

export default EmployerAccountContextProvider;
