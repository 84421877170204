import { saveTokens } from '../helpers/functions';
import HttpClient from '../utils/http-client';
import { TAuth, TCredentials, TRegistration, TSocialAuth } from './types';
import { environmentConfig } from '../Config';

class AuthApi extends HttpClient {
  public constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', false);
  }

  public login = async (credentials: TCredentials): Promise<any> =>
    this.instance.post<TAuth>('/v1/token/cognito/', {
      password: credentials.password,
      username: credentials.email,
    });

  public register = async (credentials: TRegistration): Promise<any> =>
    this.instance.post<TAuth>('/v1/registration/email/', credentials);

  public registerGoogle = async (credentials: TSocialAuth): Promise<any> =>
    this.instance.post<TSocialAuth>('/v1/registration/google/', credentials);

  public registerFacebook = async (credentials: TSocialAuth): Promise<any> =>
    this.instance.post<TSocialAuth>('/v1/registration/facebook/', credentials);

  public loginGoogle = async (credentials: TSocialAuth): Promise<any> =>
    this.instance.post<TSocialAuth>('/v1/token/google/', credentials);

  public loginFacebook = async (credentials: TSocialAuth): Promise<any> =>
    this.instance.post<TSocialAuth>('/v1/token/facebook/', credentials);

  public saveTokens = (access: string, refresh: string, username: string) => {
    saveTokens(access, refresh, username);
    this.instance.defaults.headers.common.Authorization = `Bearer ${access}`;
  };

  public resendActivationMail = (email: string) =>
    this.instance.post<{ email: string }>('/v1/users/resend_activation/', {
      email,
    });

  public remindPassword = async (email: string): Promise<any> =>
    this.instance.post<TAuth>('/v1/users/request_password_reset/', {
      email,
    });

  public resetPassword = async (
    uid: string,
    token: string,
    new_password: string
  ): Promise<any> =>
    this.instance.post<TAuth>('/v1/users/password_reset_confirm/', {
      uid,
      token,
      new_password,
    });
}

export default AuthApi;
