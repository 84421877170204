import { createUrl } from '../helpers/functions';
import HttpClient from '../utils/http-client';
import { environmentConfig } from '../Config';

const PATHS = {
  workers: '/v1/workers/',
};

export type TWorker = {
  id: number;
  avatar: string;
  rating: {
    organization: number;
    quality: number;
    atmosphere: number;
    communication: number;
    average: number;
  } | null;
  rating_count: number;
  city: string;
  first_name: string;
};

export type TWorkersResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<TWorker>;
};

export type TWorkersQueryParams = {
  page?: number;
  page_size?: number;
  distance?: string;
  city_lng?: number;
  city_lat?: number;
  city?: string;
  sex?: string;
  disability?: boolean;
  student?: boolean;
  speciality?: Array<number>;
  careers?: Array<number>;
  abilities?: Array<number>;
};

class WorkersApi extends HttpClient {
  constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public get = async (queryParams?: TWorkersQueryParams): Promise<TWorkersResponse> =>
    this.instance.get<any, TWorkersResponse>(createUrl(PATHS.workers, queryParams));
}

export default WorkersApi;
