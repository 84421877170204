import { createContext, useState, useContext } from 'react';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import AuthApi from '../api/Auth';
import paths from '../paths.json';
import customNotification from '../components/notification';
import { removeTokens, saveTokens, getErrorFromResponse } from '../helpers/functions';
import { ProfileContext } from './ProfileContext';
import { FavoriteContext } from './FavoritesContext';
import { EmployerContext } from './EmployerContext';
import { WorkerContext } from './WorkerContext';
import { useAmplifyAuthContext } from './AmplifyAuthContext';

export type AuthContextState = {
  loading: boolean;
  errorMessage: string;
  signIn: (
    email: string,
    password: string,
    remindMe: boolean,
    history: RouteComponentProps['history'],
    formikHelpers: FormikHelpers<any>
  ) => void;
  signInWithSocial: (
    token: string,
    refresh: string,
    history: RouteComponentProps['history']
  ) => void;
  signOut: (history: RouteComponentProps['history']) => void;
};

const contextDefaultValues: AuthContextState = {
  loading: false,
  errorMessage: '',
  signIn: () => {},
  signInWithSocial: () => {},
  signOut: () => {},
};

export const AuthContext = createContext<AuthContextState>(contextDefaultValues);

const AuthContextProvider: React.FC = ({ children }) => {
  const profileContext = useContext(ProfileContext);
  const favoriteContext = useContext(FavoriteContext);
  const employerContext = useContext(EmployerContext);
  const workerContext = useContext(WorkerContext);
  const { userSignIn, userSignOut } = useAmplifyAuthContext();
  const [loading, setLoading] = useState<boolean>(contextDefaultValues.loading);
  const [errorMessage, setErrorMessage] = useState<string>(
    contextDefaultValues.errorMessage
  );
  const authApi = new AuthApi();
  const { t } = useTranslation();

  const signOut = (history: RouteComponentProps['history']): void => {
    removeTokens();
    userSignOut();
    localStorage.removeItem('workinn-user-email');
    history.push(paths.loginPage);
    customNotification({
      type: 'success',
      description: t('loginPage.logoutSuccess'),
    });
    profileContext.clearContext();
    favoriteContext.clearContext();
    employerContext.clearContext();
    workerContext.clearContext();
  };
  const signIn = async (
    email: string,
    password: string,
    remindMe: boolean,
    history: RouteComponentProps['history'],
    formikHelpers: FormikHelpers<any>
  ): Promise<void> => {
    setLoading(true);
    try {
      const authData = await authApi.login({
        email,
        password,
      });
      authApi.saveTokens(authData.IdToken, authData.RefreshToken, authData.username);
      userSignIn(email, password);
      history.push(paths.dashboard);
      customNotification({
        type: 'success',
        description: t('loginPage.loginSuccess'),
      });
      localStorage.setItem('badLoginCount', '0');
      localStorage.setItem('workinn-user-email', email);
    } catch (err: any) {
      setErrorMessage(err.response?.data?.detail);
      formikHelpers.setTouched({ email: false });
      formikHelpers.setTouched({ password: false });
      localStorage.setItem(
        'badLoginCount',
        (Number(localStorage.getItem('badLoginCount')) + 1).toString()
      );
      const error = getErrorFromResponse(err?.response?.data?.errors[0]);
      customNotification({
        type: 'error',
        description: t(
          `loginPage.errors.${error.field !== null ? error.field + '_' : ''}${error.code}`
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  const signInWithSocial = (
    access: string,
    refresh: string,
    history: RouteComponentProps['history']
  ): void => {
    saveTokens(access, refresh, 'asnv-1511-asca-xsdf');
    history.push(paths.dashboard);
    customNotification({
      type: 'success',
      description: t('loginPage.loginSuccess'),
    });
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        errorMessage,
        signOut,
        signIn,
        signInWithSocial,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
