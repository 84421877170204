import HttpClient from '../../utils/http-client';
import {
  TEmployerDetailsCheckInstitutionData,
  TEmployerDetailsCheckPersonalData,
  TEmployerDetailsCheckTaxData,
  TLogo,
} from './types';
import { environmentConfig } from '../../Config';

const PATHS = {
  saveLogo: '/v1/employers/logo/',
  checkInstitutionData: 'v1/employers/institution/',
  checkPersonalData: 'v1/employers/personal_data/',
  checkTaxData: 'v1/employers/tax/',
};

class EmployerDetailsFormApi extends HttpClient {
  public constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public saveLogoData = async (credentials: TLogo): Promise<any> =>
    this.instance.post<TLogo>(PATHS.saveLogo, credentials);

  public checkPersonalData = async (
    credentials: TEmployerDetailsCheckPersonalData
  ): Promise<any> =>
    this.instance.post<TEmployerDetailsCheckPersonalData>(
      PATHS.checkPersonalData,
      credentials
    );

  public checkInstitutionData = async (
    credentials: TEmployerDetailsCheckInstitutionData
  ): Promise<any> =>
    this.instance.post<TEmployerDetailsCheckInstitutionData>(
      PATHS.checkInstitutionData,
      credentials
    );

  public checkTaxData = async (credentials: TEmployerDetailsCheckTaxData): Promise<any> =>
    this.instance.post<TEmployerDetailsCheckTaxData>(PATHS.checkTaxData, credentials);
}

export default EmployerDetailsFormApi;
