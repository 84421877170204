import { createContext, useState, useEffect, useContext } from 'react';
import ProfileApi from '../api/Profile';
import { getTokens } from '../helpers/functions';
import { ProfileContext } from './ProfileContext';

export type TProfile = {
  city: string;
  id: number;
  avatar?: string;
  logo?: string;
  rating: {
    organization: number;
    quality: number;
    atmosphere: number;
    communication: number;
  } | null;
  rating_count: number;
  company_name?: string;
  institution?: string;
  first_name?: string;
};

export type TFavoriteContextState = {
  loading: boolean;
  favorites: Array<{ id: number; profile: TProfile }>;
  addFavorite: (id: number, cb: () => void) => void;
  removeFavorite: (id: number, cb: () => void) => void;
  getFavorites: () => void;
  clearContext: () => void;
};

const FavoriteContextDefaultValues = {
  loading: false,
  favorites: [],
  addFavorite: () => {},
  removeFavorite: () => {},
  getFavorites: () => {},
  clearContext: () => {},
};

export const FavoriteContext = createContext<TFavoriteContextState>(
  FavoriteContextDefaultValues
);

const api = new ProfileApi();

const FavoriteContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<TFavoriteContextState['loading']>(
    FavoriteContextDefaultValues.loading
  );

  const [favorites, setFavorites] = useState<TFavoriteContextState['favorites']>(
    FavoriteContextDefaultValues.favorites
  );

  const { profileId } = useContext(ProfileContext);

  const addFavorite = async (id: number, successCallback: () => void) => {
    setLoading(true);
    const favorite = await api.addFavorite(id);
    setFavorites([...favorites, favorite]);
    setLoading(false);
    successCallback();
  };

  const removeFavorite = async (id: number, successCallback: () => void) => {
    const idToDelete = favorites.find(fav => fav.profile?.id === id)?.id;
    if (idToDelete) {
      setLoading(true);
      await api.removeFavorite(idToDelete);
      setFavorites(favorites.filter(fav => fav.id !== idToDelete));
      setLoading(false);
      successCallback();
    }
  };

  const getFavorites = async () => {
    if (!getTokens().access) return;
    setLoading(true);
    try {
      const favoritesData = await api.getFavorites();
      setFavorites(favoritesData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profileId) {
      getFavorites();
    }
  }, []);

  const clearContext = () => {
    setLoading(false);
    setFavorites([]);
  };

  return (
    <FavoriteContext.Provider
      value={{
        loading,
        favorites,
        addFavorite,
        removeFavorite,
        getFavorites,
        clearContext,
      }}>
      {children}
    </FavoriteContext.Provider>
  );
};

export default FavoriteContextProvider;
