import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationsApi, {
  TNotificationsResponse,
  TNotificationsSettings,
} from '../api/NotificationsApi';
import customNotification from '../components/notification';

export type TNotificationsContextState = {
  loading: boolean;
  notifications: TNotificationsResponse;
  settingsLoading: boolean;
  saveSettingsLoading: boolean;
  notificationsSettings: TNotificationsSettings;
  getNotifications: () => void;
  getNotificationsSettings: () => void;
  saveNotificationsSettings: (notifications: TNotificationsSettings) => void;
  setNotificationAsSeen: (notificationIds: string[]) => void;
  setNotificationDelete: (notificationIds: string[]) => void;
};

export const NotificationsContextDefaultState: TNotificationsContextState = {
  loading: false,
  settingsLoading: false,
  saveSettingsLoading: false,
  notifications: [],
  notificationsSettings: [],
  getNotifications: () => {},
  getNotificationsSettings: () => {},
  saveNotificationsSettings: () => {},
  setNotificationAsSeen: () => {},
  setNotificationDelete: () => {},
};

export const NotificationsContext = createContext<TNotificationsContextState>(
  NotificationsContextDefaultState
);

const api = new NotificationsApi();

const NotificationsContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<
    TNotificationsContextState['notifications']
  >(NotificationsContextDefaultState.notifications);

  const [loading, setLoading] = useState<TNotificationsContextState['loading']>(
    NotificationsContextDefaultState.loading
  );

  const [settingsLoading, setSettingsLoading] = useState<
    TNotificationsContextState['settingsLoading']
  >(NotificationsContextDefaultState.settingsLoading);

  const [saveSettingsLoading, setSaveSettingsLoading] = useState<
    TNotificationsContextState['saveSettingsLoading']
  >(NotificationsContextDefaultState.saveSettingsLoading);

  const [notificationsSettings, setNotificationsSettings] = useState<
    TNotificationsContextState['notificationsSettings']
  >(NotificationsContextDefaultState.notificationsSettings);

  const getNotifications = async () => {
    setLoading(true);
    const notificationsData = await api.getNotifications();
    setNotifications(notificationsData);
    setLoading(false);
  };

  const getNotificationsSettings = async () => {
    setSettingsLoading(true);
    try {
      const notificationsSettingsResponse = await api.getNotificationsSettings();
      setNotificationsSettings(notificationsSettingsResponse);
    } catch (err: any) {
      if (err?.response) {
        customNotification({ type: 'error', description: t('global.loadDataError') });
      }
    } finally {
      setSettingsLoading(false);
    }
  };

  const saveNotificationsSettings = async (arg: any) => {
    setSaveSettingsLoading(true);
    try {
      const notificationsResponse = await api.saveNotificationsSettings(arg);
      customNotification({ type: 'success', description: t('global.saveSuccess') });
    } catch (err: any) {
      if (err?.response) {
        customNotification({
          type: 'error',
          description: t('global.saveError'),
        });
      }
    } finally {
      setSaveSettingsLoading(false);
    }
  };

  const setNotificationAsSeen = async (ids: string[]) => {
    setSaveSettingsLoading(true);

    api.setNotificationAsSeen(ids).then(res => {
      getNotifications();
    });
  };

  const setNotificationDelete = async (ids: string[]) => {
    setSaveSettingsLoading(true);

    api.setNotificationDelete(ids).then(res => {
      getNotifications();
    });
  };

  return (
    <NotificationsContext.Provider
      value={{
        loading,
        notifications,
        settingsLoading,
        saveSettingsLoading,
        notificationsSettings,
        getNotifications,
        getNotificationsSettings,
        saveNotificationsSettings,
        setNotificationAsSeen,
        setNotificationDelete,
      }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContextProvider;
