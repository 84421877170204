// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
const appConfig = {
  apiGatewayInvokeUrl: 'https://922qcob48d.execute-api.us-east-1.amazonaws.com/Stage/',
  cognitoUserPoolId: (window as any).env.COGNITO_USER_POOL_ID,
  cognitoAppClientId: (window as any).env.COGNITO_APP_CLIENT_ID,
  cognitoIdentityPoolId: (window as any).env.COGNITO_IDENTITY_POOL_ID,
  appInstanceArn: (window as any).env.APP_INSTANCE_ARN,
  chimeRegion: 'us-east-1',
  cognitoRegion: (window as any).env.COGNITO_REGION,
  attachments_s3_bucket_name:
    'amazon-chime-sdk-chat-demo-chatattachmentsbucket-13tlklyo48msc',
};

const environmentConfig = {
  REACT_APP_API_URL: (window as any).env.REACT_APP_API_URL,
};

export { appConfig as default, environmentConfig };
