import { notification } from 'antd';
import THEME from '../utils/theme';

type NotificationType = 'error' | 'success' | 'info';

type NotificationProps = {
  description?: string;
  type?: NotificationType | string;
};

const customNotification = (props: NotificationProps) => {
  switch (props.type) {
    case 'error':
      return notification.error({
        message: 'Error',
        description: props.description,
        style: {
          borderRadius: '1rem',
          borderLeft: `2px solid ${THEME.color.red}`,
        },
      });
    case 'success':
      return notification.success({
        message: 'Success',
        description: props.description,
        style: {
          borderRadius: '1rem',
          borderLeft: `2px solid ${THEME.color.green}`,
        },
      });
    case 'info':
      return notification.info({
        message: 'Info',
        description: props.description,
        style: {
          borderRadius: '1rem',
          borderLeft: `2px solid ${THEME.color.lightBlue}`,
        },
      });
    default:
      return notification.error({
        message: props.type,
        description: props.description,
        style: {
          borderRadius: '1rem',
          borderLeft: `2px solid ${THEME.color.red}`,
        },
      });
  }
};

export default customNotification;
