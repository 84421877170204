import moment from 'moment';
import styled from 'styled-components';
import { Popover } from 'antd';
import queryString from 'query-string';
import { dateTimeFormat } from './constants';
import Text from '../components/Text';

function isEmpty(obj: Record<string, unknown>) {
  return Object.keys(obj).length === 0;
}

enum Token {
  access = 'workinn-authtoken',
  refresh = 'workinn-refreshtoken',
  username = 'workinn-username',
}

export function saveTokens(access: string, refresh: string, username: string) {
  localStorage.setItem(Token.access, access);
  localStorage.setItem(Token.refresh, refresh);
  localStorage.setItem(Token.username, username);
}

export function getTokens() {
  const access = localStorage.getItem(Token.access);
  const refresh = localStorage.getItem(Token.refresh);
  const username = localStorage.getItem(Token.username);

  return { access, refresh, username };
}

export function removeTokens() {
  localStorage.removeItem(Token.access);
  localStorage.removeItem(Token.refresh);
  localStorage.removeItem(Token.username);
}

interface ComposeProps {
  components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
  children: React.ReactNode;
}

export function Compose(props: ComposeProps) {
  const { components = [], children } = props;

  return (
    <>
      {components.reduceRight(
        (acc, Comp) => (
          <Comp>{acc}</Comp>
        ),
        children
      )}
    </>
  );
}

export const getHours = (dateTimeRange: Array<{ start: string; end: string }>) =>
  dateTimeRange
    .map(el => {
      const start = moment(el.start, dateTimeFormat);
      const end = moment(el.end, dateTimeFormat);
      return moment.duration(end.diff(start)).asHours();
    })
    .reduce((acc, next) => acc + next);

export const getDateTimeRange = (start: string, end: string, highlightDate?: boolean) => {
  const [startDate, startTime] = start.split(' ');
  const [endDate, endTime] = end.split(' ');
  return startDate === endDate ? (
    <>
      {highlightDate ? (
        <strong style={{ fontWeight: 700 }}>{startDate}</strong>
      ) : (
        startDate
      )}
      {` ${startTime} - ${endTime}`}
    </>
  ) : (
    <>
      {highlightDate ? (
        <>
          <strong style={{ fontWeight: 700 }}>{startDate}</strong> {startTime} -
          <strong style={{ fontWeight: 700 }}>{endDate}</strong> {endTime}
        </>
      ) : (
        `${start} - ${end}`
      )}
    </>
  );
};

export const getDateTimeDescription = (
  dateTimeRange: Array<{ start: string; end: string }>,
  message: string,
  highlightDate?: boolean
) => {
  if (!dateTimeRange) return 'Error';

  if (dateTimeRange.length === 1) {
    return getDateTimeRange(dateTimeRange[0].start, dateTimeRange[0].end, highlightDate);
  }

  const content = dateTimeRange.map(el => (
    <StyledText message={getDateTimeRange(el.start, el.end, highlightDate)} />
  ));

  if (highlightDate) {
    const [startDate, startTime] = dateTimeRange[0].start.split(' ');
    const [endDate, endTime] = dateTimeRange[dateTimeRange.length - 1].end.split(' ');
    return (
      <Popover title={message} content={content}>
        <strong style={{ fontWeight: 700 }}>{startDate}</strong> {startTime} -
        <strong style={{ fontWeight: 700 }}>{endDate}</strong> {endTime}
      </Popover>
    );
  }

  return (
    <Popover title={message} content={content}>
      {`${dateTimeRange[0].start} - ${dateTimeRange[dateTimeRange.length - 1].end}`}
    </Popover>
  );
};

const StyledText = styled(Text)`
  white-space: pre-wrap;
  word-break: break-all;
  display: block;
`;

export const createUrl = (queryUrl: string, queryParams = {}) => {
  if (isEmpty(queryParams)) {
    return queryUrl;
  }
  return queryUrl + '?' + queryString.stringify(queryParams);
};

export function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const flattenObject = (obj: any) => {
  const result = {};
  Object.values(obj).forEach(nestedObject => {
    Object.assign(result, nestedObject);
  });

  return result;
};

export const getErrorFromResponse = (error: any): { field: string; code: string } => {
  if (Object.keys(error).length > 1) {
    return {
      field: error.field,
      code: error.code,
    };
  }
  return getErrorFromResponse({
    ...error[Object.keys(error)[0]][0],
    field: `${Object.keys(error)[0]}.${error[Object.keys(error)[0]][0]?.field}`,
  });
};

export const getCurrentMonth = () => {
  const start = moment().startOf('month').format('YYYY-MM-DD');
  const end = moment().endOf('month').format('YYYY-MM-DD');
  return start + ':' + end;
};

export const getCurrentWeek = () => {
  const start = moment().startOf('week').format('YYYY-MM-DD');
  const end = moment().endOf('week').format('YYYY-MM-DD');
  return start + ':' + end;
};

export const getCurrentYear = () => moment().year().toString();

export const getErrorMessage = (code: string) => {
  const codes: Record<string, string> = {
    confirmed_application_date_overlap: 'myWorkinn.workerAlreadyHired',
  };

  return codes[code] || 'global.error';
};
