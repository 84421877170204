import moment from 'moment';
import { dateTimeFormat } from '../helpers/constants';
import { createUrl, getCurrentYear } from '../helpers/functions';
import HttpClient from '../utils/http-client';
import { environmentConfig } from '../Config';

const PATHS = {
  events: '/v1/calendars/events/',
  userEvents: (id: string) => `/v1/workers/${id}/calendar`,
  createEventWorker: '/v1/calendars/events/create_event_worker/',
  deleteEvent: (id: string | number) => `/v1/calendars/events/${id}/`,
};

type CreateEventData = {
  rate: number;
  dateTimeRange: Array<{ start: string; end: string }>;
  location: {
    address?: string;
    city: string;
    zip_code: string;
  };
  distance?: string | number;
  rrule?:
    | 'FREQ=DAILY'
    | 'FREQ=WEEKLY'
    | 'FREQ=WEEKLY;INTERVAL=2'
    | 'FREQ=WEEKLY;INTERVAL=3'
    | 'FREQ=MONTHLY';
};

type TEvent = {
  id: string;
  title: string;
  description: string;
  location: string;
  attendees: Array<string>;
  start_date_time: string;
  end_date_time: string;
  meta: {
    speciality?: string;
    type?: string;
    company_name: string;
    status?: string;
  };
  rrule: string;
  occurrences: Array<{
    id: string;
    title: string;
    description: string;
    location: string;
    attendees: Array<string>;
    start_date_time: string;
    end_date_time: string;
    meta: {
      company_name: string;
    };
  }>;
  status: string;
};

class UserCalendarApi extends HttpClient {
  constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public getMyEvents = async (selectedDate?: string) =>
    this.instance.get<any, any>(
      createUrl(PATHS.events, {
        start_date: '2020-01-01',
        end_date: `${selectedDate || getCurrentYear()}-12-31`,
      })
    );

  public getUserEvents = async (id: string, selectedYear?: string) =>
    this.instance.get<any, any>(
      createUrl(PATHS.userEvents(id), {
        start_date: '2020-01-01',
        end_date: `${selectedYear || getCurrentYear()}-12-31`,
      })
    );

  public add = async (
    data: CreateEventData,
    successCallback: () => void,
    errorCallback: (error: any) => void
  ): Promise<void> => {
    try {
      await this.instance.post(PATHS.createEventWorker, {
        ...data,
        start_date_time: moment(data.dateTimeRange[0].start, dateTimeFormat),
        end_date_time: moment(data.dateTimeRange[0].end, dateTimeFormat),
      });
      successCallback();
    } catch (err: any) {
      errorCallback(err?.response?.data);
    }
  };

  public removeEvent = async (id: string | number) =>
    this.instance.delete<never, any>(PATHS.deleteEvent(id));
}

export default UserCalendarApi;
