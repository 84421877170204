import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import paths from '../paths.json';
import { getTokens } from '../helpers/functions';

const ProtectedRoute: React.FC<RouteProps> = props => {
  if (!getTokens().access) {
    return <Redirect to={paths.loginPage} />;
  }
  return <Route {...props} />;
};

export default ProtectedRoute;
