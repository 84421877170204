import axios, { AxiosInstance, AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getTokens, removeTokens, saveTokens } from '../helpers/functions';

declare module 'axios' {
  export interface AxiosRequestConfig {
    skipAuthRefresh?: boolean;
  }
}

/* eslint-disable */

abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  protected constructor(baseURL: string, auth: boolean) {
    this.instance = axios.create({
      baseURL,
    });
    this.initializeResponseInterceptor();
    this.initializeRefreshTokenInterceptor(auth);
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this.handleResponse, this.handleError);
  };

  private handleResponse = (res: AxiosResponse) => {
    if (res.status !== 401) return res.data;
    return res;
  };

  protected handleError = (error: any) => Promise.reject(error);

  private refreshAccessToken = async (): Promise<string> => {
    const { refresh, username } = getTokens();
    if (!refresh) return '';
    const data = await this.instance.post<
      { refresh: string },
      { IdToken: string; username: string }
    >(
      'v1/token/cognito/refresh/',
      {
        refresh,
        username,
      },
      { skipAuthRefresh: true }
    );
    saveTokens(data.IdToken, refresh, data.username);
    return `Bearer ${data.IdToken}`;
  };

  private initializeRefreshTokenInterceptor = (auth: boolean) => {
    if (auth) {
      this.instance.interceptors.request.use(config => {
        config.headers['Authorization'] = `Bearer ${getTokens().access}`;
        return config;
      });
    }

    const refreshAuthLogic = async (failedRequest: any) => {
      try {
        const token = await this.refreshAccessToken();
        failedRequest.response.config.headers['Authorization'] = token;
        return Promise.resolve();
      } catch (err) {
        removeTokens();
        location.reload();
      }
    };

    createAuthRefreshInterceptor(this.instance, refreshAuthLogic);
  };
}

export default HttpClient;
