import { useEffect, useContext } from 'react';
import { Menu as AntMenu } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Text from '../Text';
import paths from '../../paths.json';
import { AuthContext } from '../../context/AuthContext';
import { ProfileContext } from '../../context/ProfileContext';
import { ROLES } from '../../helpers/constants';

const Menu: React.FC<{ onClickElement: () => void }> = ({ onClickElement }) => {
  const { role } = useContext(ProfileContext);
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <StyledMenu>
      <AntMenu.Item key="0" onClick={() => onClickElement()}>
        <Link to={paths.profile}>
          <Text message={t('userMenu.profile')} marginBottom="0" />
        </Link>
      </AntMenu.Item>
      <AntMenu.Item key="1" onClick={() => onClickElement()}>
        <Link to={role === ROLES.Employer ? paths.employerAccount : paths.userAccount}>
          <Text message={t('userMenu.account')} marginBottom="0" />
        </Link>
      </AntMenu.Item>
      <AntMenu.Item key="2" onClick={() => onClickElement()}>
        <Link
          to={
            role === ROLES.Employer ? '/employer-account/stats' : '/user-account/stats'
          }>
          <Text message={t('userMenu.stats')} marginBottom="0" />
        </Link>
      </AntMenu.Item>
      <AntMenu.Item
        key="3"
        onClick={(): void => {
          onClickElement();
          authContext.signOut(history);
        }}>
        <Text message={t('global.logout')} marginBottom="0" color="red" />
      </AntMenu.Item>
    </StyledMenu>
  );
};

const StyledMenu = styled(AntMenu)`
  && {
    border-radius: 1rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    background: white;
    padding: 0.25rem;

    .ant-menu-item-selected {
      background-color: transparent;
    }
  }

  .ant-menu-item {
    height: 1.5rem;
    display: flex;
    align-items: center;

    :hover {
      p {
        opacity: 0.8;
      }
    }
  }
`;

export default Menu;
