import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import { useTranslation } from 'react-i18next';
import SvgIcon from './SvgIcon';
import Text from './Text';
import PrimaryButton from './buttons/PrimaryButton';

type ModalType = 'info' | 'success' | 'warning';

interface ModalProps {
  visible: boolean;
  type: ModalType;
  title: string;
  subtitle?: string;
  onOk: () => void;
  onCancel?: () => void;
  onDeny?: () => void;
  okMessage?: string;
  weight?: number;
}

const typeToIcon = (type: ModalType) => {
  if (type === 'info') return 'infoModal';
  if (type === 'success') return 'successModal';
  if (type === 'warning') return 'warningModal';
  return 'warningModal';
};
const Modal: React.FC<ModalProps> = ({
  visible,
  type,
  title,
  subtitle,
  onOk,
  onCancel,
  onDeny,
  okMessage,
  weight,
}) => {
  const { t } = useTranslation();
  return (
    <StyledModal visible={visible} onCancel={onCancel || onOk} footer={null} width={435}>
      <IconExit type="circleClose" onClick={onCancel || onOk} />
      <Content>
        <TypeSvgIcon type={typeToIcon(type)} />
        <StyledText message={title} type="normal" weight={weight} />
        {subtitle ? <StyledText message={subtitle} type="normal" /> : null}

        <ButtonsWrapper>
          {onCancel && (
            <PrimaryButton label={t('global.cancel')} onClick={onCancel} type="ghost" />
          )}
          {onDeny && (
            <PrimaryButton label={t('global.cancel')} onClick={onDeny} type="ghost" />
          )}
          <PrimaryButton label={okMessage || t('global.accept')} onClick={onOk} />
        </ButtonsWrapper>
      </Content>
    </StyledModal>
  );
};

const StyledText = styled(Text)`
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
`;

const Content = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TypeSvgIcon = styled(SvgIcon)`
  margin-bottom: 1rem;
`;

const IconExit = styled(SvgIcon)`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const StyledModal = styled(AntModal)`
  .ant-modal-body {
    height: auto;
    padding: 1.5rem;
  }

  .ant-modal-content {
    border-radius: 1.25rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  }

  .ant-modal-close {
    display: none;
  }
`;

export default Modal;
