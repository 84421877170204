import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { Dropdown, Modal } from 'antd';
import Text from '../Text';
import Menu from './Menu';
import Placeholder from '../../assets/avatarPlaceholder.svg';
import LogoPlaceholder from '../../assets/logoPlaceholder.svg';
import SvgIcon from '../SvgIcon';
import { ProfileContext } from '../../context/ProfileContext';
import { WorkerContext } from '../../context/WorkerContext';
import { EmployerContext } from '../../context/EmployerContext';
import { ROLES } from '../../helpers/constants';
import ChangeAvatarModal from '../ChangeAvatarModal';

const UserMenu: React.FC = () => {
  const { role, profileId } = useContext(ProfileContext);
  const { data: worker, getWorkerData } = useContext(WorkerContext);
  const { data: employer, getEmployerData } = useContext(EmployerContext);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!profileId) return;
    if (role === ROLES.Employer && employer.company_name === '') {
      getEmployerData(profileId);
    } else if (role === ROLES.Worker && worker.last_name === '') {
      getWorkerData(profileId);
    }
  }, [role, profileId]);

  const getName = (userRole: ROLES | undefined) => {
    const email = localStorage.getItem('workinn-user-email');
    if (userRole === ROLES.Employer) return employer.institution || email;
    if (userRole === ROLES.Worker) {
      return worker.first_name ? `${worker.first_name} ${worker.last_name}` : email;
    }
    return email;
  };

  const getAvatar = (userRole: ROLES | undefined) => {
    if (userRole === ROLES.Employer) return employer.logo || LogoPlaceholder;
    if (userRole === ROLES.Worker) return worker.avatar || Placeholder;
    return Placeholder;
  };

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  return (
    <UserMenuWrapper>
      <Text
        message={getName(role) || ''}
        marginBottom="0"
        type="medium"
        color="secondary"
        weight={600}
      />
      <AvatarWrapper hoverText={t('employerDetailsForm.logoStep.clickToChangeAvatar')}>
        <Avatar onClick={() => setIsOpenModal(true)} src={getAvatar(role)} />
      </AvatarWrapper>
      <Modal
        visible={isOpenModal}
        centered
        footer={null}
        onCancel={() => setIsOpenModal(false)}
        title={t('employerDetailsForm.logoStep.changeAvatarTitle')}>
        <ChangeAvatarModal
          employer={role === ROLES.Employer}
          userData={role === ROLES.Employer ? employer : worker}
        />
      </Modal>
      <Dropdown
        overlay={<Menu onClickElement={() => setDropdownVisibility(false)} />}
        trigger={['click']}
        onVisibleChange={state => setDropdownVisibility(state)}
        visible={dropdownVisibility}>
        <ArrowIcon type="arrowDown" />
      </Dropdown>
    </UserMenuWrapper>
  );
};

const ArrowIcon = styled(SvgIcon)`
  cursor: pointer;
  padding: 1rem;
`;

const Avatar = styled.img`
  margin-left: 1rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const AvatarWrapper = styled.div<any>`
  position: relative;
  &::before {
    position: absolute;
    display: none;
    width: 250px;
    right: 0;
    bottom: -85px;
    border-radius: 22px;
    background-color: white;
    padding: 10px;
    content: '${props => props.hoverText}';
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: scale(0.8);
    filter: blur(5px);
    -webkit-box-shadow: 2px 7px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 2px 7px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 2px 7px 24px 0px rgba(66, 68, 90, 1);
  }

  &:hover::before {
    display: block;
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
    animation: ${fadeIn} 0.5s ease;
  }
`;

const UserMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export default UserMenu;
