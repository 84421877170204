import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme from '../utils/theme';

export type TextType =
  | 'small'
  | 'normal'
  | 'medium'
  | 'big'
  | 'large'
  | 'huge'
  | 'gigantic';

type TextColor =
  | 'primary'
  | 'secondary'
  | 'grey'
  | 'green'
  | 'red'
  | 'blue'
  | 'white'
  | 'dark';

type TextProps = {
  className?: string;
  message: ReactElement | string | string[];
  type?: TextType;
  color?: TextColor;
  weight?: number;
  italic?: boolean;
  upperCase?: boolean;
  center?: boolean;
  hoverOpacity?: number;
  marginBottom?: string;
  required?: boolean;
  title?: string;
};

const Text: React.FC<TextProps> = ({
  className,
  message,
  type,
  color,
  weight,
  italic,
  upperCase,
  center,
  hoverOpacity,
  marginBottom,
  required,
  title,
}) => (
  <Wrapper
    className={className}
    type={type}
    title={title}
    color={color}
    weight={weight}
    italic={italic}
    upperCase={upperCase}
    center={center}
    required={required}
    hoverOpacity={hoverOpacity}
    marginBottom={marginBottom}>
    {type === 'gigantic' ? (
      <h1>
        {required ? (
          <>
            <Red>*</Red>
            {message}
          </>
        ) : (
          message
        )}
      </h1>
    ) : type === 'huge' ? (
      <h1>
        {required ? (
          <>
            <Red>*</Red>
            {message}
          </>
        ) : (
          message
        )}
      </h1>
    ) : type === 'large' ? (
      <h2>
        {required ? (
          <>
            <Red>*</Red>
            {message}
          </>
        ) : (
          message
        )}
      </h2>
    ) : type === 'big' ? (
      <h3>
        {required ? (
          <>
            <Red>*</Red>
            {message}
          </>
        ) : (
          message
        )}
      </h3>
    ) : type === 'medium' ? (
      <h4>
        {required ? (
          <>
            <Red>*</Red>
            {message}
          </>
        ) : (
          message
        )}
      </h4>
    ) : type === 'normal' ? (
      <h5>
        {required ? (
          <>
            <Red>*</Red>
            {message}
          </>
        ) : (
          message
        )}
      </h5>
    ) : type === 'small' ? (
      <h6>
        {required ? (
          <>
            <Red>*</Red>
            {message}
          </>
        ) : (
          message
        )}
      </h6>
    ) : (
      <Paragraph marginBottom={marginBottom}>
        {required ? (
          <>
            <Red>*</Red>
            {message}
          </>
        ) : (
          message
        )}
      </Paragraph>
    )}
  </Wrapper>
);

const getWeightForType = (type: TextType): number => {
  switch (type) {
    case 'small':
    case 'normal':
    case 'medium':
    case 'big':
    case 'gigantic':
      return theme.fontWeight.regular;
    case 'large':
    case 'huge':
      return theme.fontWeight.bold;
    default:
      return theme.fontWeight.regular;
  }
};

const getLineHeightForType = (type: TextType): string => {
  switch (type) {
    case 'small':
      return theme.lineHeight.small;
    case 'normal':
      return theme.lineHeight.normal;
    case 'medium':
      return theme.lineHeight.medium;
    case 'big':
      return theme.lineHeight.big;
    case 'large':
      return theme.lineHeight.large;
    case 'huge':
      return theme.lineHeight.huge;
    case 'gigantic':
      return theme.lineHeight.gigantic;
    default:
      return theme.lineHeight.normal;
  }
};

const getColorForProps = (color: string): string => {
  switch (color) {
    case 'primary':
      return theme.color.primary;
    case 'white':
      return theme.color.white;
    case 'secondary':
      return theme.color.secondary;
    case 'grey':
      return theme.color.grey;
    case 'green':
      return theme.color.green;
    case 'red':
      return theme.color.red;
    case 'blue':
      return theme.color.lightBlue;
    case 'dark':
      return theme.color.dark;
    default:
      return theme.color.dark;
  }
};

// eslint-disable-next-line
const Wrapper = styled.div<any>`
  * {
    margin-bottom: ${(props): string => props.marginBottom};
    font-size: ${(props): string => props.theme.fontSize[props.type]};
    font-weight: ${(props): number =>
      props.weight ? props.weight : getWeightForType(props.type)};
    line-height: ${(props): string => getLineHeightForType(props.type)};
    color: ${(props): string => getColorForProps(props.color)};
    font-style: ${(props): string => (props.italic === true ? 'italic' : 'normal')};
    text-transform: ${(props): string => (props.upperCase ? 'uppercase' : 'initial')};
    text-align: ${(props): string => (props.center ? 'center' : 'normal')};
    display: inline;
  }
  display: inline;

  :hover {
    opacity: ${(props): number => props.hoverOpacity};
  }
`;

const Paragraph = styled.p<{ marginBottom: string | undefined }>`
  margin-bottom: ${(props): string =>
    props.marginBottom ? props.marginBottom : '0.5rem'};
`;

const Red = styled.span`
  color: ${props => props.theme.color.red};
`;

export default Text;
