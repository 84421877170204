import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import Text from './Text';

const EmptyList: React.FC<{ message?: string }> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Empty description={false} />
      <Text message={message || t('global.listEmpty')} type="medium" color="secondary" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 2rem 0;
`;

export default EmptyList;
