import { createContext, useEffect, useState } from 'react';
import UserDetailsFormApi from '../api/detailsForm/UserDetailsForm';
import { MODEL_ID } from '../helpers/constants';
import { Experience } from '../helpers/professionsAndSkills';

export enum PhonePrefix {
  POLAND_PHONE_PREFIX = '+48',
  UKRAINIAN_PHONE_PREFIX = '+380',
}

interface PersonalData {
  name: string;
  surname: string;
  pesel: string;
  nip: string;
  street: string;
  building_number?: string;
  postalCode: string;
  city: string;
  phone: string;
  phone_country_code?: PhonePrefix;
  phonePrefix: PhonePrefix;
  birthDate: string;
  sex: string | boolean;
  country: string;
  region: string;
  voivodeship?: string;
}

interface BillingData {
  usName: string;
  usAddressPartOne: string;
  usAddressPartTwo: string;
  city: string;
  postalCode: string;
  iban: string;
  country: string;
}

interface QuestionnaireData {
  employedContractInAnotherEstablishment: string | boolean;
  employedContractWageHigherThanTheMinimum: string | boolean;
  contractOfMandateInAnotherEstablishment: string | boolean;
  contractOfMandateWageHigherThanMinimum: string | boolean;
  runningABusiness: string | boolean;
  disability: string | boolean;
  student: string | boolean;
  retired: string | boolean;
  pensioner: string | boolean;
}

interface ZusData {
  voluntarySocialSecurity: string | boolean;
  dataChanges: string | boolean;
}

interface ExperienceData {
  professions: number[];
  skills: number[];
  experience: Array<Experience>;
  weight: string;
  height: string;
  hips: string;
  waist: string;
  chest: string;
}

interface SingleDeclaration {
  key: string;
  value: boolean | string;
}

interface DeclarationsFinalData {
  employment_declaration: SingleDeclaration[];
}

export type UserDetailsFormContextState = {
  personalData: PersonalData;
  billingData: BillingData;
  questionnaireData: QuestionnaireData;
  zusData: ZusData;
  experienceData: ExperienceData;
  profilePhotoUrl: string;
  setPersonalData: (personalData: PersonalData) => void;
  setBillingData: (billingData: BillingData) => void;
  setQuestionnaireData: (questionnaireData: QuestionnaireData) => void;
  setZusData: (zusData: ZusData) => void;
  setExperienceData: (experienceData: ExperienceData) => void;
  setProfilePhotoUrl: (profilePhotoUrl: string) => void;
  clearContext: () => void;
  saveAvatar: (photo: string) => any;
  checkPersonalStep: (data: PersonalData) => any;
  checkBillingDataStep: (data: BillingData) => any;
  checkCareersDataStep: (data: ExperienceData) => any;
  checkDeclarationsDataStep: (data: DeclarationsFinalData) => any;
  checkZusDataStep: (data: string | boolean) => any;
};

const contextDefaultValues: UserDetailsFormContextState = {
  personalData: {
    name: '',
    surname: '',
    pesel: '',
    nip: '',
    street: '',
    building_number: '',
    postalCode: '',
    city: '',
    phone: '',
    phone_country_code: PhonePrefix.POLAND_PHONE_PREFIX,
    phonePrefix: PhonePrefix.POLAND_PHONE_PREFIX,
    birthDate: '',
    sex: '',
    country: '',
    region: '',
  },
  billingData: {
    usName: '',
    usAddressPartOne: '',
    usAddressPartTwo: '',
    city: '',
    postalCode: '',
    iban: '',
    country: '',
  },
  questionnaireData: {
    employedContractInAnotherEstablishment: '',
    employedContractWageHigherThanTheMinimum: '',
    contractOfMandateInAnotherEstablishment: '',
    contractOfMandateWageHigherThanMinimum: '',
    runningABusiness: '',
    disability: '',
    student: '',
    retired: '',
    pensioner: '',
  },
  zusData: {
    voluntarySocialSecurity: '',
    dataChanges: '',
  },
  experienceData: {
    professions: [],
    skills: [],
    experience: [],
    weight: '',
    height: '',
    hips: '',
    waist: '',
    chest: '',
  },
  profilePhotoUrl: '',
  setPersonalData: () => {},
  setBillingData: () => {},
  setQuestionnaireData: () => {},
  setZusData: () => {},
  setExperienceData: () => {},
  setProfilePhotoUrl: () => {},
  clearContext: () => {},
  saveAvatar: () => {},
  checkPersonalStep: () => {},
  checkBillingDataStep: () => {},
  checkCareersDataStep: () => {},
  checkDeclarationsDataStep: () => {},
  checkZusDataStep: () => {},
};

const booleanFromOption = (value: string | boolean) =>
  typeof value === 'boolean' ? value : false;

export const UserDetailsFormContext =
  createContext<UserDetailsFormContextState>(contextDefaultValues);

const UserDetailsFormContextProvider: React.FC = ({ children }) => {
  const [personalData, setPersonalData] = useState<PersonalData>(
    contextDefaultValues.personalData
  );
  const [billingData, setBillingData] = useState<BillingData>(
    contextDefaultValues.billingData
  );
  const [questionnaireData, setQuestionnaireData] = useState<QuestionnaireData>(
    contextDefaultValues.questionnaireData
  );
  const [zusData, setZusData] = useState<ZusData>(contextDefaultValues.zusData);
  const [experienceData, setExperienceData] = useState<ExperienceData>(
    contextDefaultValues.experienceData
  );
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string>(
    contextDefaultValues.profilePhotoUrl
  );

  const clearContext = () => {
    setPersonalData(contextDefaultValues.personalData);
    setBillingData(contextDefaultValues.billingData);
    setQuestionnaireData(contextDefaultValues.questionnaireData);
    setZusData(contextDefaultValues.zusData);
    setExperienceData(contextDefaultValues.experienceData);
    setProfilePhotoUrl(contextDefaultValues.profilePhotoUrl);
  };

  const userDetailsFormApi = new UserDetailsFormApi();

  const saveAvatar = (logo: string) =>
    userDetailsFormApi.saveAvatarData({ avatar: logo });

  const checkPersonalStep = (data: PersonalData) =>
    userDetailsFormApi.checkPersonalData({
      first_name: data.name,
      last_name: data.surname,
      date_of_birth: data.birthDate,
      PESEL: data.pesel,
      sex: booleanFromOption(data.sex) ? 'FEMALE' : 'MALE',
      NIP: data.nip,
      street: data.street,
      zip_code: data.postalCode,
      city: data.city,
      country: data.country,
      voivodeship: data.region,
      phone: data.phonePrefix + data.phone,
      building_number: data.building_number,
    });

  const checkDeclarationsDataStep = (data: DeclarationsFinalData) => {
    const employmentDeclarations = [
      {
        key: 'EMPLOYED_CONTRACT_IN_ANOTHER_ESTABLISHMENT',
        value: data.employment_declaration.some(
          declaration =>
            declaration.key === 'EMPLOYED_CONTRACT_IN_ANOTHER_ESTABLISHMENT' &&
            declaration.value
        ),
      },
      {
        key: 'EMPLOYED_CONTRACT_WAGE_HIGHER_THAN_THE_MINIMUM',
        value: data.employment_declaration.some(
          declaration =>
            declaration.key === 'EMPLOYED_CONTRACT_WAGE_HIGHER_THAN_THE_MINIMUM' &&
            declaration.value
        ),
      },
      {
        key: 'CONTRACT_OF_MANDATE_IN_ANOTHER_ESTABLISHMENT',
        value: data.employment_declaration.some(
          declaration =>
            declaration.key === 'CONTRACT_OF_MANDATE_IN_ANOTHER_ESTABLISHMENT' &&
            declaration.value
        ),
      },
      {
        key: 'CONTRACT_OF_MANDATE_WAGE_HIGHER_THAN_MINIMUM',
        value: data.employment_declaration.some(
          declaration =>
            declaration.key === 'CONTRACT_OF_MANDATE_WAGE_HIGHER_THAN_MINIMUM' &&
            declaration.value
        ),
      },
      {
        key: 'RUNNING_A_BUSINESS',
        value: data.employment_declaration.some(
          declaration => declaration.key === 'RUNNING_A_BUSINESS' && declaration.value
        ),
      },
      {
        key: 'DISABILITY',
        value: data.employment_declaration.some(
          declaration => declaration.key === 'DISABILITY' && declaration.value
        ),
      },
      {
        key: 'STUDENT',
        value: data.employment_declaration.some(
          declaration => declaration.key === 'STUDENT' && declaration.value
        ),
      },
      {
        key: 'RETIRED',
        value: data.employment_declaration.some(
          declaration => declaration.key === 'RETIRED' && declaration.value
        ),
      },
      {
        key: 'PENSIONER',
        value: data.employment_declaration.some(
          declaration => declaration.key === 'PENSIONER' && declaration.value
        ),
      },
    ];

    return userDetailsFormApi.checkDeclarationsData({
      employment_declaration: employmentDeclarations,
    });
  };

  const checkZusDataStep = (data: string | boolean) =>
    userDetailsFormApi.checkZusData(data);

  const checkBillingDataStep = (data: BillingData) =>
    userDetailsFormApi.checkBillingData({
      name: data.usName,
      address_line_1: data.usAddressPartOne,
      address_line_2: data.usAddressPartTwo,
      city: data.city,
      country: data.country,
      zip_code: data.postalCode,
      bank_account_number: data.iban,
    });

  const checkCareersDataStep = (data: ExperienceData) =>
    userDetailsFormApi.checkCareersData({
      careers: data.professions,
      abilities: data.skills,
      experience: data.experience,
      dimensions: data.professions.includes(MODEL_ID)
        ? {
            weight: Number(data.weight),
            height: Number(data.height),
            hips: Number(data.hips),
            waist: Number(data.waist),
            chest: Number(data.chest),
          }
        : null,
    });

  return (
    <UserDetailsFormContext.Provider
      value={{
        personalData,
        billingData,
        questionnaireData,
        zusData,
        experienceData,
        profilePhotoUrl,
        setPersonalData,
        setBillingData,
        setQuestionnaireData,
        setZusData,
        setExperienceData,
        setProfilePhotoUrl,
        clearContext,
        saveAvatar,
        checkPersonalStep,
        checkBillingDataStep,
        checkCareersDataStep,
        checkDeclarationsDataStep,
        checkZusDataStep,
      }}>
      {children}
    </UserDetailsFormContext.Provider>
  );
};

export default UserDetailsFormContextProvider;
