import { environmentConfig } from '../Config';
import { SpecialitieType, Translation } from '../context/WorkerContext';
import HttpClient from '../utils/http-client';

const PATHS = {
  careersList: '/v1/careers/',
  abilitiesList: '/v1/abilities/',
};

export type TSkills = {
  id: number;
  name: string;
  specialities: SpecialitieType[];
  translations: Translation[];
};

export type TAbilities = {
  id: number;
  translations: Translation[];
};

export type TSkillsResponse = Array<TSkills>;

export type TAbilitiesResponse = Array<TAbilities>;

class SkillsApi extends HttpClient {
  constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public getCareersList = async () =>
    this.instance.get<void, TSkillsResponse>(PATHS.careersList);

  public getAbilitiesList = async () =>
    this.instance.get<void, TAbilitiesResponse>(PATHS.abilitiesList);
}

export default SkillsApi;
