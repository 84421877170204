import HttpClient from '../../utils/http-client';
import {
  TSaveUserDetails,
  TUserDetailsCheckPersonalData,
  TUserDetailsCheckBillingData,
  TUserDetailsCheckCareersData,
  TAvatar,
} from './types';
import { environmentConfig } from '../../Config';

interface SingleDeclaration {
  key: string;
  value: boolean | string;
}

interface DeclarationsFinalData {
  employment_declaration: SingleDeclaration[];
}
const PATHS = {
  saveAvatar: '/v1/workers/avatar/',
  checkPersonalData: 'v1/workers/personal_data/',
  checkBillingData: 'v1/workers/billing_data/',
  checkCareersData: 'v1/workers/careers/',
  checkDeclarationsData: '/v1/workers/declarations/',
  checkZusData: '/v1/workers/social/',
};

class UserDetailsFormApi extends HttpClient {
  public constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public saveAvatarData = async (credentials: TAvatar): Promise<any> =>
    this.instance.post<TAvatar>(PATHS.saveAvatar, credentials);

  public checkPersonalData = async (
    credentials: TUserDetailsCheckPersonalData
  ): Promise<any> =>
    this.instance.post<TUserDetailsCheckPersonalData>(
      PATHS.checkPersonalData,
      credentials
    );

  public checkBillingData = async (
    credentials: TUserDetailsCheckBillingData
  ): Promise<any> =>
    this.instance.post<TUserDetailsCheckBillingData>(PATHS.checkBillingData, credentials);

  public checkCareersData = async (
    credentials: TUserDetailsCheckCareersData
  ): Promise<any> =>
    this.instance.post<TUserDetailsCheckCareersData>(PATHS.checkCareersData, credentials);

  public checkDeclarationsData = async (
    credentials: DeclarationsFinalData
  ): Promise<any> =>
    this.instance.post<DeclarationsFinalData>(PATHS.checkDeclarationsData, credentials);

  public checkZusData = async (credentials: string | boolean): Promise<any> =>
    this.instance.post<string | boolean>(PATHS.checkZusData, credentials);
}

export default UserDetailsFormApi;
