import { createContext, useState } from 'react';
import UserCalendarApi from '../api/UserCalendarApi';
import { SpecialitieType } from './WorkerContext';

export type Event = {
  attendees: Array<string>;
  calendar_id: string;
  description: string;
  end_date_time: string;
  excluded_occurrences: Array<any>;
  id: string;
  location: string;
  meta: {
    speciality: SpecialitieType;
    company_name: string;
    institution: string;
    type?: string;
    status?: string;
    rate?: string;
    job_offer?: {
      id: string;
    };
    distance?: string;
    location?: {
      city?: string;
    };
  };
  occurrences: Array<any>;
  rrule?: string;
  start_date_time: string;
  title: string;
  until_date_time: string;
};

export type TUserCalendarContextState = {
  loading: boolean;
  //   events: {
  //     count: number;
  //     next: string | null;
  //     previous: string | null;
  //     results: Array<TOffer>;
  //   };
  events: Array<Event>;
  userEvents: Array<Event>;
  getEvents: (date?: string) => void;
  getUserEvents: (id: string, year?: string) => void;
};

export const userCalendarContextDefaultState: TUserCalendarContextState = {
  loading: false,
  events: [],
  userEvents: [],
  getEvents: () => {},
  getUserEvents: () => {},
};

export const UserCalendarContext = createContext<TUserCalendarContextState>(
  userCalendarContextDefaultState
);

const api = new UserCalendarApi();

const UserCalendarContextProvider: React.FC = ({ children }) => {
  const [events, setEvents] = useState<TUserCalendarContextState['events']>(
    userCalendarContextDefaultState.events
  );

  const [userEvents, setUserEvents] = useState<TUserCalendarContextState['userEvents']>(
    userCalendarContextDefaultState.userEvents
  );

  const [loading, setLoading] = useState<TUserCalendarContextState['loading']>(
    userCalendarContextDefaultState.loading
  );

  const getEvents = async (endDate?: string) => {
    setLoading(true);
    const eventsData = await api.getMyEvents(endDate);
    setEvents(eventsData);
    setLoading(false);
  };

  const getUserEvents = async (id: string, selectedYear?: string) => {
    setLoading(true);
    const userEventsData = await api.getUserEvents(id, selectedYear);

    setUserEvents(
      userEventsData.filter(
        (event: any) =>
          event.meta?.type === 'DISPOSITION' || event.description === 'Disposition'
      )
    );
    setLoading(false);
  };

  return (
    <UserCalendarContext.Provider
      value={{
        loading,
        events,
        userEvents,
        getEvents,
        getUserEvents,
      }}>
      {children}
    </UserCalendarContext.Provider>
  );
};

export default UserCalendarContextProvider;
