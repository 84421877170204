import { createContext, useState, useEffect } from 'react';
import ProfileApi from '../api/Profile';
import { ROLES } from '../helpers/constants';
import { getTokens } from '../helpers/functions';

export type TProfileContextState = {
  loading: boolean;
  role: ROLES | undefined;
  profileId: number | undefined;
  profileCompleted?: boolean;
  profileVerified?: boolean;
  setUserRole: () => void;
  clearContext: () => void;
  setProfileCompleted: (arg: boolean) => void;
};

const ProfileContextDefaultValues = {
  loading: false,
  role: undefined,
  profileId: undefined,
  profileCompleted: false,
  profileVerified: false,
  setUserRole: () => {},
  clearContext: () => {},
  setProfileCompleted: () => {},
};

export const ProfileContext = createContext<TProfileContextState>(
  ProfileContextDefaultValues
);

const api = new ProfileApi();

const ProfileContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<TProfileContextState['loading']>(
    ProfileContextDefaultValues.loading
  );
  const [role, setRole] = useState<TProfileContextState['role']>(
    ProfileContextDefaultValues.role
  );
  const [profileId, setProfileId] = useState<TProfileContextState['profileId']>(
    ProfileContextDefaultValues.profileId
  );
  const [profileCompleted, setProfileCompleted] = useState<
    TProfileContextState['profileCompleted']
  >(ProfileContextDefaultValues.profileCompleted);

  const [profileVerified, setProfileVerified] = useState<
    TProfileContextState['profileVerified']
  >(ProfileContextDefaultValues.profileVerified);

  const setUserRole = async () => {
    if (!getTokens().access) return;
    setLoading(true);
    const clientType = await api.getInfo();
    setRole(clientType?.client_type);
    setProfileId(clientType?.profile_id);
    setProfileCompleted(clientType?.profile_completed);
    setProfileVerified(clientType?.verified);
    setLoading(false);
  };

  useEffect(() => {
    setUserRole();
  }, []);

  const clearContext = () => {
    setRole(ProfileContextDefaultValues.role);
    setProfileId(ProfileContextDefaultValues.profileId);
    setProfileCompleted(ProfileContextDefaultValues.profileCompleted);
  };

  return (
    <ProfileContext.Provider
      value={{
        loading,
        role,
        profileId,
        profileCompleted,
        profileVerified,
        setUserRole,
        clearContext,
        setProfileCompleted,
      }}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContextProvider;
