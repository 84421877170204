import { createContext, useState } from 'react';
import SkillsApi, { TAbilitiesResponse, TSkillsResponse } from '../api/SkillsApi';

export type TSkillsContextState = {
  careersList: TSkillsResponse;
  abilitiesList: TAbilitiesResponse;
  getCareersData: () => void;
  getAbilitiesData: () => void;
};

export const TSkillsContextDefaultState: TSkillsContextState = {
  careersList: [],
  abilitiesList: [],
  getCareersData: () => {},
  getAbilitiesData: () => {},
};

export const SkillsContext = createContext<TSkillsContextState>(
  TSkillsContextDefaultState
);

const api = new SkillsApi();

const SkillsContextProvider: React.FC = ({ children }) => {
  const [careersList, setCareersList] = useState<TSkillsContextState['careersList']>(
    TSkillsContextDefaultState.careersList
  );

  const getCareersData = async () => {
    const careersData = await api.getCareersList();
    setCareersList(careersData);
  };

  const [abilitiesList, setAbilitiesList] = useState<
    TSkillsContextState['abilitiesList']
  >(TSkillsContextDefaultState.abilitiesList);

  const getAbilitiesData = async () => {
    const abilitiesData = await api.getAbilitiesList();
    setAbilitiesList(abilitiesData);
  };

  return (
    <SkillsContext.Provider
      value={{ careersList, abilitiesList, getCareersData, getAbilitiesData }}>
      {children}
    </SkillsContext.Provider>
  );
};

export default SkillsContextProvider;
