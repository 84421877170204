import { createContext, useState } from 'react';
import OffersApi, { TOffersQueryParams, TMyOffersQueryParams } from '../api/Offers';
import { DEFAULT_PAGE_SIZE } from '../helpers/constants';
import { SpecialitieType, TranslationSkills } from './WorkerContext';

enum TWorkerStatus {
  candidate = 'CANDIDATE',
  confirmed = 'CONFIRMED',
  forSettlement = 'FOR_SETTLEMENT',
  settled = 'SETTLED',
  unsettled = 'UNSETTLED',
  notCome = 'WORKER_NOT_COME',
  settlementToImprove = 'SETTLEMENT_TO_IMPROVED',
  settlementSent = 'SETTLEMENT_SENT',
  executed = 'EXECUTED',
  rejected = 'REJECTED',
  resignationRequest = 'RESIGNATION_REQUEST',
  settlementRejected = 'SETTLEMENT_REJECTED',
}

export type TOffer = {
  id: number;
  title: string;
  event_dates: Array<Array<string>>;
  institution?: string;
  location: {
    city: string;
    address: string;
    zip_code?: string;
  };
  speciality: SpecialitieType;
  company_name?: string;
  abilities: TranslationSkills[];
  contents: string;
  requirements: string;
  hourly_rate: string;
  number_of_vacancies: number;
  number_of_workers_needed?: number;
  compatible: boolean;
  applied: boolean;
  is_active?: boolean;
  can_application: {
    status: boolean;
    reasons?: Array<string>;
  };
  employer_profile: number;
  logo?: string;
  created_at: string;
  status?: string;
  workers?: Array<{
    worker_profile: {
      id: number;
      first_name: string;
      rating: null;
      rating_count: number;
      last_name: string;
      avatar: string;
    };
    status: TWorkerStatus;
  }>;
  can_send_opinion?: boolean;
  can_open_settlement?: boolean;
  matching_workers?: Array<{
    id: string | number;
    avatar: string;
    first_name: string;
    rating: null;
    rating_count: number;
  }>;
};

type OffersContextState = {
  loading: boolean;
  errorMessage: string;
  params: TOffersQueryParams;
  updateData: (queryParams?: TOffersQueryParams) => void;
  getMyOffers: (queryParams?: TMyOffersQueryParams) => void;
  clearSelectedOffer: () => void;
  getSelectedOffer: (id: string) => void;
  apply: (
    offerId: number,
    successCB: () => void,
    errorCB: (code: string) => void
  ) => void;
  inviteWorker: (
    offerId: number | string,
    workerId: number,
    successCB: () => void,
    errorCB: (code: string) => void
  ) => void;
  changePage: (newPage: number) => void;
  changeWorkerStatusInOffer: (
    offerId: number,
    workerId: number,
    status: TWorkerStatus
  ) => void;
  removeOffer: (id: number) => void;
  page: number;
  pageSize: number;
  changePageSize: (newPageSize: number) => void;
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Array<TOffer>;
  };
  selectedOffer:
    | (TOffer & { employer_profile: { id: number; name: string; institution?: string } })
    | undefined;
};

const offersContextInitialState: OffersContextState = {
  loading: false,
  errorMessage: '',
  params: {},
  updateData: () => {},
  getMyOffers: () => {},
  getSelectedOffer: () => {},
  inviteWorker: () => {},
  clearSelectedOffer: () => {},
  apply: () => {},
  changePage: () => {},
  changeWorkerStatusInOffer: () => {},
  removeOffer: () => {},
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  changePageSize: () => {},
  selectedOffer: undefined,
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

const api = new OffersApi();

export const OffersContext = createContext<OffersContextState>(offersContextInitialState);

const OffersContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<OffersContextState['loading']>(
    offersContextInitialState.loading
  );
  const [errorMessage, setErrorMessage] = useState<OffersContextState['errorMessage']>(
    offersContextInitialState.errorMessage
  );
  const [page, setPage] = useState<OffersContextState['page']>(
    offersContextInitialState.page
  );
  const [pageSize, changePageSize] = useState<OffersContextState['pageSize']>(
    offersContextInitialState.pageSize
  );
  const [data, setData] = useState<OffersContextState['data']>(
    offersContextInitialState.data
  );
  const [selectedOffer, setSelectedOffer] = useState<OffersContextState['selectedOffer']>(
    offersContextInitialState.selectedOffer
  );
  const [params, setParams] = useState<OffersContextState['params']>(
    offersContextInitialState.params
  );

  const updateData = async (queryParams?: TOffersQueryParams) => {
    setLoading(true);
    const offersData = await api.get({
      ...queryParams,
      page_size: pageSize || DEFAULT_PAGE_SIZE,
    });
    if (queryParams?.page) setPage(queryParams.page);
    setData(offersData);
    setParams(queryParams || {});
    setLoading(false);
  };

  const getMyOffers = async (queryParams?: TMyOffersQueryParams) => {
    setLoading(true);
    const offersData = await api.getMyOffers({
      ...queryParams,
      page_size: pageSize || DEFAULT_PAGE_SIZE,
    });
    if (queryParams?.page) setPage(queryParams.page);
    if (queryParams?.page_size) changePageSize(queryParams.page_size);
    setData(offersData);
    setParams(queryParams || {});
    setLoading(false);
  };

  const getSelectedOffer = async (id: string) => {
    setLoading(true);
    const offerData = await api.getSingleOffer(id);
    setSelectedOffer(offerData);
    setLoading(false);
  };

  const inviteWorker = async (
    offerId: number | string,
    workerId: number,
    successCB: () => void,
    errorCB: (err: string) => void
  ) => {
    try {
      await api.inviteWorker(offerId, workerId);
      successCB();
    } catch (err: any) {
      errorCB(err?.response?.data?.errors[0].code);
    }
  };

  const apply = async (
    offerId: number,
    successCB: () => void,
    errorCB: (err: string) => void
  ) => {
    try {
      await api.apply(offerId);
      successCB();
    } catch (err: any) {
      errorCB(err?.response?.data?.errors[0].code);
    }
  };

  const changeWorkerStatusInOffer = (
    offerId: number,
    workerId: number,
    status: TWorkerStatus
  ) => {
    const newOffers = data.results.map(offer => {
      if (offer.id === offerId) {
        const newWorkers = offer?.workers?.map(worker =>
          worker.worker_profile.id === workerId ? { ...worker, status } : worker
        );
        return { ...offer, workers: newWorkers };
      }
      return offer;
    });
    setData({ ...data, results: newOffers });
  };

  const clearSelectedOffer = () => {
    setSelectedOffer(offersContextInitialState.selectedOffer);
  };

  const changePage = (newPage: number) => {
    setPage(newPage);
  };

  const removeOffer = (id: number) => {
    setData({ ...data, results: data.results.filter(offer => offer.id !== id) });
  };
  return (
    <OffersContext.Provider
      value={{
        clearSelectedOffer,
        loading,
        errorMessage,
        updateData,
        params,
        data,
        page,
        pageSize,
        selectedOffer,
        getSelectedOffer,
        getMyOffers,
        inviteWorker,
        apply,
        changePage,
        changePageSize,
        changeWorkerStatusInOffer,
        removeOffer,
      }}>
      {children}
    </OffersContext.Provider>
  );
};

export default OffersContextProvider;
