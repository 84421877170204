import { useEffect } from 'react';
// eslint-disable-next-line
import { RouteComponentProps, withRouter } from 'react-router-dom';

const ScrollToPageTop: React.FC<RouteComponentProps> = ({ history }) => {
  useEffect(() => {
    const unListen = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return (): void => {
      unListen();
    };
  }, [history]);

  return null;
};

export default withRouter(ScrollToPageTop);
