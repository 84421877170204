import { Spin } from 'antd';
import styled from 'styled-components';

const StyledSpin = styled(Spin)`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  .ant-spin-dot-item {
    background-color: ${props => props.theme.color.primary};
  }
`;

export default StyledSpin;
