import { createContext, useState } from 'react';
import WorkersApi, { TWorker, TWorkersQueryParams } from '../api/Workers';

type WorkersContextState = {
  loading: boolean;
  errorMessage: string;
  updateData: (queryParams?: TWorkersQueryParams) => void;
  page: number;
  pageSize: number;
  params: TWorkersQueryParams;
  changePage: (page: number) => void;
  changePageSize: (pageSize: number) => void;
  clearContext: () => void;
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Array<TWorker>;
  };
};

const workersContextInitialState: WorkersContextState = {
  loading: false,
  errorMessage: '',
  updateData: () => {},
  page: 1,
  changePage: () => {},
  changePageSize: () => {},
  pageSize: 10,
  params: {},
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  clearContext: () => {},
};

const api = new WorkersApi();

export const WorkersContext = createContext<WorkersContextState>(
  workersContextInitialState
);

const WorkersContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<WorkersContextState['loading']>(
    workersContextInitialState.loading
  );
  const [errorMessage, setErrorMessage] = useState<WorkersContextState['errorMessage']>(
    workersContextInitialState.errorMessage
  );
  const [page, changePage] = useState<WorkersContextState['page']>(
    workersContextInitialState.page
  );
  const [pageSize, changePageSize] = useState<WorkersContextState['pageSize']>(
    workersContextInitialState.pageSize
  );
  const [data, setData] = useState<WorkersContextState['data']>(
    workersContextInitialState.data
  );
  const [params, setParams] = useState<WorkersContextState['params']>(
    workersContextInitialState.params
  );

  const updateData = async (queryParams?: TWorkersQueryParams) => {
    setLoading(true);
    const offersData = await api.get({
      ...queryParams,
      page_size: pageSize,
    });
    if (queryParams?.page) changePage(queryParams.page);
    if (queryParams?.page_size) changePageSize(queryParams.page_size);
    setData(offersData);
    setParams(queryParams || {});
    setLoading(false);
  };

  const clearContext = () => {
    setData(workersContextInitialState.data);
    setParams(workersContextInitialState.params);
  };

  return (
    <WorkersContext.Provider
      value={{
        loading,
        errorMessage,
        updateData,
        data,
        page,
        changePage,
        changePageSize,
        pageSize,
        params,
        clearContext,
      }}>
      {children}
    </WorkersContext.Provider>
  );
};

export default WorkersContextProvider;
