import HttpClient from '../../utils/http-client';
import { createUrl } from '../../helpers/functions';
import {
  TSaveEmployerBasicData,
  TSaveEmployerInstitutionData,
  TSaveEmployerTaxData,
  TEmployerData,
  TOffersStats,
  TWorkersStats,
} from './types';
import { environmentConfig } from '../../Config';

const PATHS = {
  save: (id: string | number) => `/v1/employers/${id}/details/`,
  load: (id: string | number) => `/v1/employers/${id}/details/`,
  getOffersStats: '/v1/statistic/offers/',
  getWorkersStats: '/v1/statistic/workers/',
  getWorkersContract: 'v1/statistic/workers/pdf/',
};

type ID = string | number;

class EmployerAccountApi extends HttpClient {
  public constructor() {
    super(environmentConfig.REACT_APP_API_URL || '', true);
  }

  public save = async (
    id: string | number,
    credentials:
      | TSaveEmployerBasicData
      | TSaveEmployerInstitutionData
      | TSaveEmployerTaxData
  ): Promise<any> =>
    this.instance.patch<
      TSaveEmployerBasicData | TSaveEmployerInstitutionData | TSaveEmployerTaxData
    >(PATHS.save(id), credentials);

  public load = async (id: ID): Promise<TEmployerData> =>
    this.instance.get<never, TEmployerData>(PATHS.load(id));

  public getOffersStats = async (queryParrams: {
    start_date: string;
    end_date: string;
  }): Promise<TOffersStats> =>
    this.instance.get<never, TOffersStats>(createUrl(PATHS.getOffersStats, queryParrams));

  public getWorkersStats = async (queryParrams: {
    start_date: string;
    end_date: string;
    settled?: boolean;
  }): Promise<TWorkersStats> =>
    this.instance.get<never, TWorkersStats>(
      createUrl(PATHS.getWorkersStats, queryParrams)
    );

  public getWorkersContract = async (data: {
    start_date: string;
    end_date: string;
    status: any;
  }) =>
    this.instance.get<any, { file_key: string }>(
      createUrl(PATHS.getWorkersContract, data)
    );
}

export default EmployerAccountApi;
